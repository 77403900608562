import {App, createApp} from "vue";
import ChatRootComponent from "~/chat/vue/ChatRootComponent.vue";
import InlineWidgetWatcher from "~/chat/ts/service/InlineWidgetWatcher";
import {Intervals} from "~/ts/library/delay/Intervals";
import ChatComponentMount from "~/chat/ts/service/ChatComponentMount";
import {prepareChatApp} from "~/chat/ts/initialize/PrepareChatApp";


let container: HTMLDivElement;
let vueApp: App;
let parentInterval: number;


export default class InitVue {

    public static init(): App | null {
        container = document.createElement("div");

        (new InlineWidgetWatcher()).watch();

        let body = document.body;

        body.appendChild(container);
        //vueApp = new ChatRootComponent();
        vueApp = prepareChatApp(createApp(ChatRootComponent));

        ChatComponentMount.mount(container, vueApp, [], true);

        let parent: HTMLElement;
        parentInterval = Intervals.set(() => {
            parent = container.parentElement;
            if (parent && parent != body) {
                body.appendChild(container);
            }
        }, 1000);
        return vueApp;
    }

    public static destroy() {
        if (vueApp) {
            if (parentInterval) {
                Intervals.clear(parentInterval);
            }
            let el = vueApp._instance?.proxy?.$el?.parentElement;
            vueApp.unmount();
            try {
                el.parentElement.removeChild(el);
            } catch (e) {

            }
            container = null;
            vueApp = null
        }
    }
}

