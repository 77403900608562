import {Intervals} from "~/ts/library/delay/Intervals";
import ChatComponentMount from "~/chat/ts/service/ChatComponentMount";
import {CHAT_INLINE_COMPONENT_CLASS, CHAT_ROOT_CLASS_INHERIT} from "~/chat/ts/Constants";
import ConsoleWrapper from "~/ts/library/Console";
import {App} from "vue";

let attribute = "chat-component-mounted";

interface IComponentsItem {
    app: App,
    container: HTMLElement
}

const matchesFunctionNames = ["matches", "msMatchesSelector", "webkitMatchesSelector"];



export default abstract class InlineContentWatcher {
    private interval: number;
    private components: IComponentsItem[];

    private static instances: InlineContentWatcher[] = [];

    constructor() {
        this.components = [];
    }

    private unwatch() {
        Intervals.clear(this.interval);
    }

    watch() {
        this.unwatch();
        InlineContentWatcher.instances.push(this);
        this.interval = Intervals.set(() => this.make(), 1000);
        this.make();
    }

    public static destroyAll() {
        for (let instance of this.instances) {
            instance.unwatch();
            for (let item of instance.components) {
                instance.destroyComonent(item);
            }
            instance.components = [];
        }
        this.instances = [];
    }

    private containerCount: number;

    protected abstract get selector(): string;

    protected get className(): string[] {
        return [CHAT_ROOT_CLASS_INHERIT, CHAT_INLINE_COMPONENT_CLASS];
    }

    protected abstract getComponent(container: HTMLElement): Promise<App>;

    private destroyComonent(item: IComponentsItem) {
        item.app.unmount();
        item.container.innerHTML = "";
        item.container.removeAttribute(attribute);
    }

    public static isMatches(element: HTMLElement, selector: string): boolean {
        //TODO: Internet explorer HACK может расстанемся
        for (let functionName of matchesFunctionNames) {
            if (typeof (element as any)[functionName] == "function") {
                return (element as any)[functionName](selector);
            }
        }
        return false;
    }

    private async make() {

        this.components = this.components.filter(item => {
            if (!item.container.parentElement || !InlineContentWatcher.isMatches(item.container, this.selector)) {
                this.destroyComonent(item);
                return false;
            }
            return true;
        });

        let containers = document.querySelectorAll(this.selector);
        let count = containers.length;
        if (count != this.containerCount) {
            this.containerCount = count;
            this.onContainersCountChange(count);
        }
        for (let i = 0; i < containers.length; i++) {
            let container: HTMLElement = containers[i] as any;
            if (!container.getAttribute(attribute)) {
                container.setAttribute(attribute, "1");
                try {
                    let app = await this.getComponent(container);
                    ChatComponentMount.mount(container as any, app, this.className);
                    this.components.push({
                        app,
                        container
                    });
                } catch (e) {
                    ConsoleWrapper.warn("Не удалось смонтировать inline контент", e);
                }
            }
        }

    }

    protected onContainersCountChange(count: number) {

    }

    protected attr(container: HTMLElement, key: string, def: string = ""): string {
        let result = container.getAttribute(`data-${key}`);
        return result != null ? result : def;
    }
}