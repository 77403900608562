import {ITriggerParams} from "~/chat/ts/data/ISiteParams";
import Dictionary from "~/ts/library/Dictionary";
import {computed, ExtractPropTypes, getCurrentInstance, onUpdated, PropType, watch} from "vue";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import {ConfigStore} from "~/chat/ts/store/Config";

export function useTriggerProps() {
    return {
        config: {
            type: Object as PropType<ITriggerParams>,
            required: true
        },
        additionalStyles: {
            type: Object as PropType<Dictionary<string>>,
            required: true
        },
        inlineStyles: {
            type: Object as PropType<Dictionary<string>>,
            required: true
        }
    }
}


export type USE_TRIGGER_EMITS = {
    (e: "default-bubble-text"): void,
    (e: "updated"): void
};

export function useTrigger(
    props: Readonly<ExtractPropTypes<ReturnType<typeof useTriggerProps>>>,
    p: {
        isBubbleTextAvailable: () => boolean,
    }
) {
    //TODO: надо проверять работоспособность
    /*
    defineEmits<{
        (e: "default-bubble-text"): void,
        (e: "updated"): void
    }>();
    */
    let isOnline = computed(() => {
        return !OperatorsStore.isOffline.value || ConfigStore.siteParams.value.free;
    });

    /*
    let isMobileMenuOpened = computed(() => {
        return TriggerStore.isTriggerMenuOpened && TriggerStore.triggerMenuDisplayTypeId == TRIGGER_MENU_TYPE_MOBILE;
    });
    */

    let text = computed(() => {
        return isOnline.value ? props.config.onlineText : props.config.offlineText;
    });

    let defaultBubbleText = computed(() => {
        return p.isBubbleTextAvailable() ? text.value : null;
    });

    watch(defaultBubbleText, () => emitDefaultBubbleText());

    let instance = getCurrentInstance().proxy;


    function emitDefaultBubbleText() {
        instance.$emit("default-bubble-text", defaultBubbleText.value);
    }


    onUpdated(() => {
        instance.$emit("updated");
    });

    let icon = computed(() => {
        let icon = props.config.icon;
        return OperatorsStore.isOffline.value && !ConfigStore.siteParams.value.free ? icon.offline : icon.online;
    });

    emitDefaultBubbleText();

    return {
        text,
        icon,
        isOnline,
        defaultBubbleText
    };
}