import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {TriggerStore} from "~/chat/ts/store/Trigger";
import {ConfigStore} from "~/chat/ts/store/Config";
import NotificationMessageList from "~/chat/vue/notification/NotificationMessageList.vue";
import TriggerPopup from "~/chat/vue/trigger/TriggerPopup.vue";
import {useNotification, AbstractNotificationItem} from "~/chat/vue/notification/useNotification";
import {computed, ref} from "vue";


const __default__ = {
    name: "TriggerBubble"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    defaultText: {}
  },
  setup(__props: any) {

let props = __props;

let {close, open, getMessages} = useNotification();

let isDefaultTextDisplayed = ref(false);

let items = computed<AbstractNotificationItem[]>(() => {
    let result: AbstractNotificationItem[] = [];
    if (ConfigStore.bubbleOnNewMessage.value) {
        result = getMessages();
    }

    isDefaultTextDisplayed.value = !!(!result.length && props.defaultText && !TriggerStore.isDefaultTextClosed.value);
    if (isDefaultTextDisplayed.value) {
        result.push({
            id: "defaultText",
            type: "text",
            text: props.defaultText
        });
    }

    return result;
});


function bubbleClose() {
    if (isDefaultTextDisplayed.value) {
        TriggerStore.CLOSE_DEFAULT_TEXT();
    } else {
        close();
    }
}

return (_ctx: any,_cache: any) => {
  return (_unref(items).length)
    ? (_openBlock(), _createBlock(TriggerPopup, {
        key: 0,
        closable: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(open)(!_unref(isDefaultTextDisplayed)))),
        onClose: bubbleClose
      }, {
        default: _withCtx(() => [
          _createVNode(NotificationMessageList, {
            messages: _unref(items),
            class: "message-list"
          }, null, 8, ["messages"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})