import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
    IMessageButton,
    MESSAGE_BUTTON_ACTION_CLOSE_WIDGET,
    MESSAGE_BUTTON_ACTION_JAVASCRIPT,
    MESSAGE_BUTTON_ACTION_OPEN_URL,
    MESSAGE_BUTTON_ACTION_OPEN_WIDGET_TAB,
    MESSAGE_BUTTON_ACTION_SEND_MESSAGE_TO_OPERATOR,
    MESSAGE_BUTTON_ACTION_SEND_TO_ANALYTICS,
    MESSAGE_BUTTON_ACTION_START_CHAT_BOT,
    MESSAGE_BUTTON_ACTION_TARGET, MessageButtonActionCloseWidgetParamsInterface,
    MessageButtonActionJavascriptParamsInterface,
    MessageButtonActionOpenUrlParamsInterface,
    MessageButtonActionOpenWidgetTabParamsInterface,
    MessageButtonActionSendMessageToOperatorParamsInterface,
    MessageButtonActionSendToAnalyticsParamsInterface, MessageButtonActionStartChatBotParamsInterface,
    MessageButtonActionTargetParamsInterface
} from "~/chat/ts/data/chat/IMessage";
import ChatFormButton from "~/chat/vue/widget/form/fields/ChatFormButton.vue";
import showTab from "~/chat/ts/service/api/methods/ShowTab";
import MessageSender from "~/chat/ts/service/messages/MessageSender";
import setOperator from "~/chat/ts/service/api/methods/SetOperator";
import {ConfigStore} from "~/chat/ts/store/Config";
import {IWidgetTabParams, TAB_TYPE_REVIEWS} from "~/chat/ts/data/ITab";
import openReviewsTab from "~/chat/ts/service/api/methods/OpenReviewsTab";
import SafeEval from "~/ts/library/SafeEval";
import AnalyticCounters from "~/chat/ts/service/AnalyticCounters";
import setTarget from "~/chat/ts/service/api/methods/SetTarget";
import closeSupport from "~/chat/ts/service/api/methods/CloseSupport";
import {WidgetStore} from "~/chat/ts/store/Widget";
import Dictionary from "~/ts/library/Dictionary";


const __default__ = {
    name: "MessageButton"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    button: {},
    round: { type: Boolean, default: true }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let onClickHandlers: Dictionary<(params: any) => void | boolean | Promise<any>> = {
    [MESSAGE_BUTTON_ACTION_SEND_MESSAGE_TO_OPERATOR]: async (params: MessageButtonActionSendMessageToOperatorParamsInterface) => {
        //OperatorsStore.setTemporaryAvailableOperatorSelect(true);
        let recipient = params.messageRecipient;
        if (recipient) {

            let parsedRecipient: { type: "operator" | "group", value: string };
            try {
                parsedRecipient = JSON.parse(recipient);
            } catch (e) {
                parsedRecipient = {type: "operator", value: recipient};
            }
            if (parsedRecipient.type == "operator") {
                setOperator(parsedRecipient.value);
            } else {
                setOperator(null, parsedRecipient.value);
            }
        }
        let result = (new MessageSender({
            text: props.button.text
        })).send();
        WidgetStore.openChatTab();
        await result;
        //OperatorsStore.setTemporaryAvailableOperatorSelect(false);

    },
    [MESSAGE_BUTTON_ACTION_OPEN_WIDGET_TAB]: (params: MessageButtonActionOpenWidgetTabParamsInterface) => {
        if (params.tabId) {
            let tab: IWidgetTabParams = ConfigStore.tabsDictionary.value[params.tabId];
            if (tab && tab.type == TAB_TYPE_REVIEWS) {
                if (params.reviewTabType == "form") {
                    openReviewsTab(true);
                    return true;
                }
            }
            showTab({id: params.tabId});
        }
        return true;
    },
    [MESSAGE_BUTTON_ACTION_OPEN_URL]: (params: MessageButtonActionOpenUrlParamsInterface) => {
        if (params.url) {
            let url = params.url;
            if (params.newWindow) {
                window.open(url);
            } else {
                window.location.href = url;
            }
        }
        return params.hideButtonsAfterClick !== true;
    },
    [MESSAGE_BUTTON_ACTION_JAVASCRIPT]: (params: MessageButtonActionJavascriptParamsInterface) => {
        SafeEval.make(params.script);
    },
    [MESSAGE_BUTTON_ACTION_SEND_TO_ANALYTICS]: (params: MessageButtonActionSendToAnalyticsParamsInterface) => {
        AnalyticCounters.send(params.eventId, null, null, true);
        return true;
    },
    [MESSAGE_BUTTON_ACTION_TARGET]: (params: MessageButtonActionTargetParamsInterface) => {
        let reason = params.reason;
        if (typeof reason == "string" && reason.length) {
            setTarget(reason);
        }
    },
    [MESSAGE_BUTTON_ACTION_CLOSE_WIDGET]: (params: MessageButtonActionCloseWidgetParamsInterface) => {
        closeSupport();
    },
    [MESSAGE_BUTTON_ACTION_START_CHAT_BOT]: (params: MessageButtonActionStartChatBotParamsInterface) => {
        (new MessageSender({
            text: props.button.text,
        })).setRunBotSchemeId(params.schemeId).send();
        WidgetStore.openChatTab();
    },
};

async function onClick() {
    let dontMakeEmit = null;
    for (let action of props.button.actions) {
        let params = action.params ? action.params : {};
        let typeId = action.type;
        if (onClickHandlers[typeId]) {
            try {
                let result = onClickHandlers[typeId](params);
                dontMakeEmit = result === true && dontMakeEmit !== false;
            } catch (e) {
                console.warn("error on button handler", e);
            }
        }
    }
    if (!dontMakeEmit) {
        emits('click');
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ChatFormButton, {
    inverse: "",
    type: "primary",
    round: _ctx.round,
    onClick: onClick
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.button.text), 1)
    ]),
    _: 1
  }, 8, ["round"]))
}
}

})