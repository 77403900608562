import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {
    ref,
    computed,
    watch,
    onMounted,
    onBeforeUnmount,
    getCurrentInstance,
    inject,
    createApp, App
} from 'vue';
import {STATIC_SERVER} from "~/chat/ts/CommonConstants";
import Events from "~/ts/library/Events";
import Random from "~/ts/library/Random";
import ChatApiRequest from "~/chat/ts/service/request/ChatApiRequest";
import SvgIcon from "~/chat/vue/misc/SvgIcon.vue";
import {ChatEventManagerInstance} from "~/chat/ts/ChatEventManager";
import ConsoleWrapper from "~/ts/library/Console";
import ChatNodeRequest from "~/chat/ts/service/ChatNodeRequest";
import {addDisposableEvent} from "~/ts/vuePlugins/useDisposableEvent";
import emojify from "~/core-ui/vue/ui/emojify";
import {LightboxInjection} from "~/core-ui/ts/LightboxInjection";
import StringHelper from "~/ts/library/StringHelper";
import {__} from "~/ts/library/Translate";
import onHtmlifyClick from "~/core-ui/vue/ui/OnHtmlifyClick";
import {WidgetStore} from "~/chat/ts/store/Widget";



import {LINK_REGEXP} from "~/core-ui/vue/ui/CoreUiHtmlifyHelper";

const tempDiv: HTMLDivElement = document.createElement("div");

const scriptRegexp = /<script(.*?)?(src=["'](.*)["'])?>((.|\s)*?)<\/script>/gim;

const sizeProps = ["width", "height"];

const UPLOADS_DIR = "/uploads/tinymce";

const decodeTextarea = document.createElement("textarea");

const __default__ = {
    name: "ChatHtmlify"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    tag: { default: "span" },
    value: {},
    emoji: { type: Boolean },
    links: { type: Boolean },
    snippet: { type: Boolean },
    escape: { type: Boolean, default: true },
    nl2br: { type: Boolean, default: false },
    bbcode: { type: Boolean },
    contenteditable: { type: Boolean }
  },
  emits: ["update:image-only", "height-increase"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let svgList = ref<App[]>([]);
let content = ref<HTMLSpanElement>();

watch(computed(() => props.value), () => {
    emitImageOnly(false);
    parse();
});

onMounted(() => parse());

onBeforeUnmount(() => destroySvg());

function destroySvg() {
    while (svgList.value.length) {
        svgList.value.shift().unmount();
    }
}


function parse() {
    try {
        if (props.value != null) {
            let container = getContainer();
            if (container) {
                destroySvg();
                let html = props.value;

                if (props.escape && !props.bbcode) {
                    html = escapeHtml(html);
                }

                if (props.bbcode) {
                    html = StringHelper.bbcodeToHtml(html).split(/<br\s?\/?>/i).join("\n");
                }
                html = fixImages(html);
                html = addCopyTitle(html);
                if (props.links) {
                    html = linkify(html);
                } else if (props.snippet && !props.escape) {
                    html = snippifyHtml(html);
                }

                container.innerHTML = html;
                if (props.emoji) {
                    html = emojify(container);
                }

                setImagesOnLoad();
                initializeLinkOnClick();
                fix(container);

                if (!props.escape) {
                    evalScript(html);
                }

            }
        }
    } catch (e) {
        alert(e);
    }
}

let instance = getCurrentInstance().proxy;

function evalScript(html: string) {
    let scriptMatch: any;
    while (scriptMatch = scriptRegexp.exec(html)) {
        if (scriptMatch[3]) {
            try {
                let newScript = document.createElement("script");
                newScript.src = scriptMatch[3];
                instance.$el.appendChild(newScript);
            } catch (e) {
                ConsoleWrapper.error("Error while insert script in tab", scriptMatch[3], e);
            }
        }
        if (scriptMatch[4]) {
            (function (code: string) {
                try {
                    eval(scriptMatch[4]);
                } catch (e) {
                    ConsoleWrapper.error("Error in eval inline tab script", code, e);
                }
            })(scriptMatch[4]);
        }
    }
}

function getContainer(): HTMLElement | null {
    return content.value as HTMLElement;
}

function escapeHtml(html: string): string {
    tempDiv.textContent = html;
    html = tempDiv.innerHTML;
    tempDiv.textContent = "";
    return html;
}

function initializeLinkOnClick() {
    let container = getContainer();
    if (container) {
        let links = container.getElementsByTagName("a");
        for (let i = 0; i < links.length; i++) {
            let link: HTMLLinkElement | any = links[i] as any;
            if (!link.isOnClickInitialized) {
                link.isOnClickInitialized = true;
                link.addEventListener("click", () => {
                    ChatApiRequest.make({
                        method: "clientClickOnLink",
                        p: {
                            link: link.getAttribute("href")
                        }
                    });
                });
            }
        }
    }
}

function fixImages(html: string) {
    html = html.replace(/<img/ig, '<simg');

    tempDiv.innerHTML = html;
    let images: HTMLImageElement[] = tempDiv.getElementsByTagName("simg") as any;
    for (let i = 0; i < images.length; i++) {
        let image = images[i];
        let src = image.getAttribute("src");
        if (src[0] == "/" && src[1] != "/") {
            image.setAttribute("src", STATIC_SERVER + src);
        }
    }
    html = tempDiv.innerHTML.replace(/<simg/ig, '<img');
    tempDiv.innerHTML = "";
    return html;
}

function addCopyTitle(html: string) {
    tempDiv.innerHTML = html;
    let list: HTMLImageElement[] = tempDiv.querySelectorAll("[data-copyblock]") as any;
    for (let i = 0; i < list.length; i++) {
        let span = list[i];
        span.setAttribute("title", __("COPY"));
    }
    html = tempDiv.innerHTML;
    tempDiv.innerHTML = "";
    return html;
}

function setImagesOnLoad() {
    let container = getContainer();
    if (container) {
        let images = container.getElementsByTagName("img");
        for (let i = 0; i < images.length; i++) {
            setImageOnLoad(images[i]);
        }
    }
}

function setImageOnLoad(img: HTMLImageElement) {
    Events.once("load", img, () => {
        heightIncrease(img);
    });
}

let lightbox = inject(LightboxInjection, null);

function getSnippetId(href: string): string | null {
    let snippetId: string = null;
    if (href.toLowerCase().indexOf("http") != -1) {
        snippetId = Random.uid();
        addDisposableEvent(ChatEventManagerInstance.once(snippetId, (data: CometOperatorApplication.SnippetCometMessageOpenApiModel) => {
            let a = document.getElementById(snippetId) as HTMLLinkElement;
            if (a) {
                let snippet: HTMLElement;
                let isImage = data.type == "image";
                if (isImage) {
                    let img = document.createElement("img");
                    img.src = data.snipurl
                        .split("//pic.me-talk.ru/")
                        .join("//pic.me-talk.ru/");
                    img.setAttribute("style", `width: ${data.image_size[0]}px !important; aspect-ratio: ${data.image_size[0]} / ${data.image_size[1]} !important;`);
                    snippet = document.createElement("a");
                    snippet.setAttribute("target", "_blank");
                    snippet.setAttribute("href", href);
                    snippet.appendChild(img);
                    setImageOnLoad(img);

                    if (lightbox) {
                        snippet.addEventListener("click", function (e) {
                            e.preventDefault();
                            lightbox(href);
                        });
                    }
                }
                if (snippet) {
                    let div = document.createElement("div");
                    div.setAttribute("class", "snippet-wrapper");
                    div.appendChild(snippet);
                    a.parentElement.replaceChild(div, a);
                    //a.replaceWith(div);
                    heightIncrease(div);
                    initializeLinkOnClick();
                }
                if (isImage) {
                    let container = getContainer();
                    if (container) {
                        if (!container.innerText.trim().length) {
                            emitImageOnly(true);
                        }
                    }
                }
            }
        }));
        requestSnippet(href, snippetId);
    }
    return snippetId;
}

function emitImageOnly(value: boolean) {
    emits("update:image-only", value);
}

function heightIncrease(element: HTMLElement) {
    emits("height-increase", element.offsetHeight);
}

async function requestSnippet(href: string, snippetId: string) {
    try {
        let body: CometWidget.CometClientRequestSnippetBodyOpenApiModel = {
            ...ChatNodeRequest.getClientCredentials(),
            url: href,
            id: snippetId
        };
        await (new ChatNodeRequest("/requestSnippetByClient"))
            .setBody(body)
            .send();
    } catch (e) {
        ConsoleWrapper.error("Error on snippet request");
    }
}

function snippifyHtml(html: string) {
    let regexp = /href="(.*?)"/gi;
    //let match = [...(html as any).matchAll(/href="(.*?)"/gi)];
    let item;
    while (item = regexp.exec(html)) {
        //for (let item of match) {
        html = html.split(item[0]).join(item[0] + ` id="${getSnippetId(item[1])}"`);
    }
    return html;
}

function htmlDecode(text: string) {
    decodeTextarea.innerHTML = text;
    return decodeTextarea.innerText;
}

function linkify(html: string) {
    return html.replace(LINK_REGEXP, link => {
        link = htmlDecode(link);
        let a = document.createElement("a");
        a.target = "_blank";
        a.innerText = link;
        a.href = link;

        if (props.snippet) {
            let id = getSnippetId(link);
            if (id) {
                a.id = id;
            }
        }

        return a.outerHTML;
    });
}

function fixUploadUrl(url: string): string {
    return STATIC_SERVER + url;
}

function fix(element: HTMLElement) {
    let style = element.style;
    for (let i = 0; i < style.length; i++) {
        let prop = style[i];
        style.setProperty(prop, style.getPropertyValue(prop), "important");
    }

    for (let prop of sizeProps) {
        let value = element.getAttribute(prop);
        if (value != null) {
            style.setProperty(prop, `${value}px`, "important");
            if (element.tagName.toLowerCase() == "img") {
                break;
            }
        }
    }

    if (element.tagName == "IMG") {
        var src = element.getAttribute("src");
        if (src && src.indexOf(UPLOADS_DIR) === 0) {
            element.setAttribute("src", fixUploadUrl(src));
        }

    } else if (element.tagName == "A") {
        let href = element.getAttribute("href");
        if (href && href.indexOf(UPLOADS_DIR) === 0) {
            element.setAttribute("href", fixUploadUrl(href));
        }
    } else {
        let classList = element.classList;
        if (classList) {
            for (let i = 0; i < classList.length; i++) {
                let className = classList[i];
                if (className.indexOf("icon-") == 0) {
                    let svg = createApp({
                        ...SvgIcon
                    }, {
                        iconName: className,
                        iconStyle: "light"
                    });
                    svgList.value.push(svg as any);
                    element.innerHTML = "<div></div>";
                    svg.mount(element.children[0]);
                }
            }
        }
    }
    if (element.children) {
        for (let i = 0; i < element.children.length; i++) {
            let child = element.children.item(i);
            if (child.nodeType == 1) {
                fix(child as HTMLElement);
            }
        }
    }
}

function onClick(event: MouseEvent) {
    onHtmlifyClick(event, {
        onCopySuccess: () => {
            WidgetStore.showModalMessage({
                text: __("COPY_SUCCESS"),
                vue: instance
            });
        },
        onCopyError: () => {
            WidgetStore.showModalMessage({
                text: __("COPY_ERROR"),
                vue: instance
            });
        }
    })
}

return (_ctx: any,_cache: any) => {
  return (_ctx.value)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
        key: 0,
        class: _normalizeClass(['htmlify', _ctx.escape && _ctx.nl2br ? 'pre' : null]),
        ref_key: "content",
        ref: content,
        contenteditable: _ctx.contenteditable ? 'true' : 'false',
        onClick: onClick
      }, null, 8, ["class", "contenteditable"]))
    : _createCommentVNode("", true)
}
}

})