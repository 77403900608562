import Dictionary from "~/ts/library/Dictionary";
import {
    ACTION_FILTER_CC,
    ACTION_FILTER_CITY,
    ACTION_FILTER_COUNTRY,
    ACTION_FILTER_COUNTRY_CODE,
    ACTION_FILTER_IP,
    ACTION_FILTER_REGION
} from "~/chat/ts/data/AutoActions";
import Filters from "~/chat/ts/service/autoActions/Filters";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {ConfigStore} from "~/chat/ts/store/Config";
import {ClientStore} from "~/chat/ts/store/Client";
import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";
import {ChatTabStore, WidgetDataInterface} from "~/chat/ts/store/ChatTab";
import IsTargetChangedListener from "~/chat/ts/service/listen/IsTargetChangedListener";

export default class GeoipListener extends AbstractListenerAction {
    public getAction(): string {
        return "geoip";
    }

    public onAction(p: IPayload): void {
        if (p.geoip) {
            let essences = [ACTION_FILTER_CITY, ACTION_FILTER_COUNTRY, ACTION_FILTER_REGION];
            let languageId = ConfigStore.language.value;
            for (let i = 0; i < essences.length; i++) {
                let filterName = essences[i];
                let value: Dictionary<string> = (p.geoip as any)[filterName];
                if (value) {
                    let valueArray = ObjectHelper.arrayFromDictionary(value);
                    Filters.set(filterName, valueArray);
                    if (valueArray.length) {
                        let langValue = value[value[languageId] != null ? languageId : "en"];
                        Filters.set(filterName + "_lang", langValue);
                    }
                }
            }
        }
        if (p.ip) {
            Filters.set(ACTION_FILTER_IP, p.ip);
        }
        if (p.countryCode) {
            ClientStore.SET_COUNTRY_CODE(p.countryCode);
            Filters.set(ACTION_FILTER_COUNTRY_CODE, p.countryCode);
        }
        if (p.cc) {
            ClientStore.SET_CC(p.cc);
            Filters.set(ACTION_FILTER_CC, p.cc);
        }
        if (p.widgetData) {
            ChatTabStore.setWidetData(p.widgetData);
        }

        if (typeof p.isTarget == "boolean") {
            IsTargetChangedListener.setFilter(p.isTarget);
        }
        if (p.fields) {
            ClientStore.update(p.fields/*, false*/);
        }
    }
}

interface IPayload {
    geoip?: {
        city?: Dictionary<string>,
        country?: Dictionary<string>,
        region?: Dictionary<string>
    },
    countryCode?: string,
    ip?: string,
    cc?: number,
    isTarget: boolean;
    fields?: Dictionary<any>,
    widgetData?: WidgetDataInterface
}

/*
{"id":1,"channel":"14755221258159fdb93bdc95487328cc4cc83208","text":{"action":"editMessage","id":184744097,"text":"в пердакшен!"},"time":"1561049769050","tag":0}
 */