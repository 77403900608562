import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge badge-background"
}
const _hoisted_2 = { class: "badge-number" }

import {computed} from 'vue';
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";


const __default__ = {
    name: "TriggerUnreadedCount"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let unreadedCount = computed(() => {
    return MessageStorage.getMessageCountForBadge();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "zoom" }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_unref(unreadedCount) > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(unreadedCount)), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})