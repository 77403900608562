import {
    CHAT_EVENT_CONTACTS_PROVIDED,
    CHAT_EVENT_OPERATOR_SELECTED_MANUALY,
    FORM_TYPE_AFTER_CHAT_START,
    FORM_TYPE_LEAD_GEN,
    FORM_TYPE_QUESTION_CATEGORY,
    QUESTION_CATEGORY_FIELD_NAME
} from "~/chat/ts/Constants";
import {
    COLLECT_CONTACT_FIELD_DISABLED,
    COLLECT_CONTACT_FIELD_REQUIRED,
    CollectContactFields,
    CollectContactFieldState,
    ONLINE_CONTACTS_MODE_AFTER,
    WIDGET_OFFLINE_MODE_FORM,
    WIDGET_ONLINE_BOT_MODE,
    WIDGET_ONLINE_MODE
} from "~/chat/ts/data/ISiteParams";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import {ConfigStore} from "~/chat/ts/store/Config";
import Dictionary from "~/ts/library/Dictionary";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import {ChatTabModes, IChatTabMode} from "~/chat/ts/data/chat/ChatTabModes";
import AnalyticCounters from "~/chat/ts/service/AnalyticCounters";
import {__} from "~/ts/library/Translate";
import {ChatEventManagerInstance} from "~/chat/ts/ChatEventManager";
import {TAB_TYPE_REVIEWS} from "~/chat/ts/data/ITab";
import {ISelectField} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {ClientStore} from "~/chat/ts/store/Client";
import {computed, ref} from "vue";
import Filters from "~/chat/ts/service/autoActions/Filters";
import {ACTION_FILTER_BOT_RUNNING, ACTION_FILTER_DIALOG_OPENED} from "~/chat/ts/data/AutoActions";

const TEXTAREA_VALUE_KEY = "chatTextareaValue";
const REPLY_TO_MESSAGE_ID = "replyToMessageId";
const RATE_REFUSED = "requestRateRefused";
const IS_BOT_RUNNING_KEY = "isBotRunning";
const CASCADE_MESSAGE_ID_KEY = "cascadeMessageId";
const IS_DIALOG_RUNNING_KEY = "isDialogRunning";
const OPERATOR_MESSAGES_COUNT_KEY = "operatorMessagesCount";
const CLIENT_MESSAGES_COUNT_KEY = "clientMessagesCount";
const PINNED_MESSAGES_KEY = "pinnedMessages";
const CLIENT_TAGS_KEY = "clientTags";

class ChatTab {
    askForContactsFields? = ref<CollectContactFields>();
    askForContactsFormType = ref<string>();
    askForContactsIsKeepOnline = ref<boolean>(false);
    messageSendBlocked = ref(false);
    isScrolledToBottom = ref(true);
    isExportChatDisplayed = ref(false);
    showRateRequestAfterMessage = ref<number>();
    keepOnlineMessageInHistoryAfterMessage = ref<number>();

    displayRateRequestInFooter = ref(false);
    readonly textareaValue = computed<string>(() => {
        return StorageInstance.get(TEXTAREA_VALUE_KEY);
    });
    readonly replyToMessageId = computed<number>(() => {
        return StorageInstance.get(REPLY_TO_MESSAGE_ID);
    });
    readonly isBotRunning = computed(() => {
        return !!StorageInstance.get(IS_BOT_RUNNING_KEY);
    });
    readonly cascadeMessageId = computed<number>(() => {
        return StorageInstance.get(CASCADE_MESSAGE_ID_KEY);
    });
    readonly isDialogRunning = computed(() => {
        return !!StorageInstance.get(IS_DIALOG_RUNNING_KEY);
    });
    readonly isRequestRateRefused = computed<boolean>(() => {
        return StorageInstance.get(RATE_REFUSED, false);
    });
    readonly isContactsFormRequired = computed(() => {
        return this.askForContactsFormType.value != FORM_TYPE_AFTER_CHAT_START;
    });
    readonly isShowFillContactsLink = computed(() => {
        let result = false;
        let startDialogFormConfig = this.dialogStartForm.value;
        if (startDialogFormConfig.afterStart.showAgain.enabled) {
            let check = this.isContactsFormFilled();

            if (check === false) {
                if (startDialogFormConfig.collectContactsMode == ONLINE_CONTACTS_MODE_AFTER) {
                    if (!this.askForContactsFormType.value) {
                        if (this.isContactsFormAfterChatStartWasDismissedOnce()) {
                            result = true;
                        }
                    }
                }
            }
        }
        return result;
    });
    readonly isMessageSendBlocked = computed(() => {
        return this.messageSendBlocked.value;
    });
    readonly collectContacts = computed(() => {
        return this.askForContactsFields.value;
    });
    readonly isOfflineFormButtonDisplayed = computed(() => {
        return OperatorsStore.isOffline.value && ConfigStore.siteParams.value.params.leadGen.mode == WIDGET_OFFLINE_MODE_FORM && !this.isBotRunning.value;
    });
    readonly isHistoryDisplayed = computed(() => {
        return !this.isOfflineFormDisplayed.value;
    });
    readonly chatTabMode = computed<IChatTabMode>(() => {
        return ChatTabModes[OperatorsStore.isOffline.value ? ConfigStore.siteParams.value.params.leadGen.mode : (this.isBotRunning.value ? WIDGET_ONLINE_BOT_MODE : WIDGET_ONLINE_MODE)];
    });
    readonly dialogStartForm = computed(() => {
        let mode = this.chatTabMode.value;
        return mode.getStartDialogForm ? mode.getStartDialogForm() : ConfigStore.siteParams.value.params.online.startDialogFormConfig;
    });

    setTextareaValue(value?: string) {
        StorageInstance.set(TEXTAREA_VALUE_KEY, value);
    }

    setWidetData(p: WidgetDataInterface) {
        this.setIsBotRunning(p.isBotRunning, p.cascadeMessageId);
        this.setIsDialogRunning(p.isDialogRunning);
        StorageInstance.set(OPERATOR_MESSAGES_COUNT_KEY, p.operatorMessageCountAtDialog);
        StorageInstance.set(CLIENT_MESSAGES_COUNT_KEY, p.clientMessageCountAtDialog);
        StorageInstance.set(PINNED_MESSAGES_KEY, p.pinnedMessages);
        StorageInstance.set(CLIENT_TAGS_KEY, p.clientTagsHash);
    }

    get clientTags(): string[] {
        return StorageInstance.get(CLIENT_TAGS_KEY) ?? [];
    }

    get pinnedMessages(): PinnedMessageInterface[] {
        return StorageInstance.get(PINNED_MESSAGES_KEY) ?? [];
    }

    unpinMessage(messageId: number) {
        StorageInstance.set(PINNED_MESSAGES_KEY, this.pinnedMessages.filter(message => message.id != messageId));
    }

    get operatorMessageCountAtDialog(): number {
        return StorageInstance.get(OPERATOR_MESSAGES_COUNT_KEY) ?? 0;
    }

    get clientMessageCountAtDialog(): number {
        return StorageInstance.get(CLIENT_MESSAGES_COUNT_KEY) ?? 0;
    }

    private setIsBotRunning(value: boolean, cascadeMessageId?: number) {
        StorageInstance.set(IS_BOT_RUNNING_KEY, !!value);
        StorageInstance.set(CASCADE_MESSAGE_ID_KEY, cascadeMessageId);
        Filters.set(ACTION_FILTER_BOT_RUNNING, value ? 1 : 0);
    }

    readonly isWidgetMustBeHiddenBecauseOfOffline = computed(() => {
        return this.chatTabMode.value.hideWidget;
    });
    private operatorsSelectDisplayed = ref(false);
    readonly isOperatorsSelectDisplayed = computed(() => {
        return this.operatorsSelectDisplayed.value && OperatorsStore.isSelectAvailable.value;
    });
    private offlineFormDisplayed = ref<boolean>();
    readonly isOfflineFormDisplayed = computed(() => {
        let offlineFormDisplayed = this.offlineFormDisplayed.value;
        if (typeof offlineFormDisplayed != "boolean") {
            offlineFormDisplayed = !ConfigStore.siteParams.value.params.leadGen.pmForm.isAlwaysShowHistoryFirst;
        }
        return this.isOfflineFormButtonDisplayed.value && offlineFormDisplayed;
    });

    SET_REPLY_TO_MESSAGE_ID(id: number) {
        StorageInstance.set(REPLY_TO_MESSAGE_ID, id);
    }

    showRateRequestAfterNewMessage(messageId: number) {
        let requestRateConfig = ConfigStore.siteParams.value.params.rate.requestRate;
        if (requestRateConfig.enabled && !this.isRequestRateRefused.value) {
            let count = requestRateConfig.messagesCountType == "operator" ? ChatTabStore.operatorMessageCountAtDialog : ChatTabStore.clientMessageCountAtDialog;
            if (count >= requestRateConfig.afterMessagesCount) {
                this.showRateRequest(messageId);
            }
        }
    }

    showRateRequest(messageId: number) {
        if (ConfigStore.tabs.value.find(tab => tab.type == TAB_TYPE_REVIEWS)) {
            this.SHOW_RATE_REQUEST(messageId);
            this.CANCEL_RATE_REQUEST_IN_FOOTER();
        }
    }

    public isNeedAddQuestionCategoryToFieldsList() {
        let config = ConfigStore.siteParams.value.params;
        let questionCategories = config.leadGen.questionCategories;
        let questionCategoriesList = questionCategories.list;

        if (questionCategories.ask) {
            if (OperatorsStore.isSelectOperatorsAvailable.value) {
                //Фильтруем категории только в том случае, если человек может выбрать отдел самостоятельно
                let group = OperatorsStore.selected.value.group;
                if (group && group.questionCategories.length) {
                    questionCategoriesList = questionCategoriesList.filter(category => group.questionCategories.indexOf(category) > -1);
                }
            }
            if (questionCategoriesList.length) {
                let questionCategoryField = ConfigStore.siteParams.value.formElements[QUESTION_CATEGORY_FIELD_NAME] as any as ISelectField;
                if (questionCategoryField) {
                    questionCategoryField.options = questionCategoriesList.map(category => ({
                        descr: category,
                        value: category,
                        default: false
                    }));
                    return true;
                }
            }
        }
        return false;
    }

    private getContactsFormFilledKey(formType: string, real: boolean): string {
        let result = `contactsFormFilled/${formType}`;
        if (real) {
            result += '/real';
        }
        return result;
    }

    isContactsFormAfterChatStartWasDismissedOnce(): boolean {
        return StorageInstance.get(this.getContactsFormFilledKey(FORM_TYPE_AFTER_CHAT_START, false));
    }

    setContactsFormFilled(formType: string, filled: boolean) {
        StorageInstance.set(this.getContactsFormFilledKey(formType, false), true);
        StorageInstance.set(this.getContactsFormFilledKey(formType, true), filled);
    }

    public CANCEL_RATE_REQUEST(displayInFooter: boolean = true) {
        this.showRateRequestAfterMessage.value = null;
        this.displayRateRequestInFooter.value = displayInFooter;
        StorageInstance.set(RATE_REFUSED, true);
    }

    public CANCEL_RATE_REQUEST_IN_FOOTER() {
        this.displayRateRequestInFooter.value = false;
    }

    setIsDialogRunning(value: boolean) {
        let changed = this.isDialogRunning.value != value;
        Filters.set(ACTION_FILTER_DIALOG_OPENED, value ? 1 : 0);
        StorageInstance.set(IS_DIALOG_RUNNING_KEY, !!value);
        if (!value && changed) {
            for (let operator of OperatorsStore.operatorsList.value) {
                OperatorsStore.unsetOperatorTemporaryVisible(operator);
            }
            for (let group of OperatorsStore.groupList.value) {
                OperatorsStore.unsetOperatorGroupTemporaryVisible(group);
            }
        }
    }

    public SET_IS_SCROLLED_TO_BOTTOM(value: boolean) {
        this.isScrolledToBottom.value = value;
    }

    public SET_EXPORT_CHAT_DISPLAYED(value: boolean) {
        this.isExportChatDisplayed.value = value;
    }

    async showOperatorsSelect() {
        if (OperatorsStore.isSelectAvailable.value) {
            this.operatorsSelectDisplayed.value = true;
            this.BLOCK_MESSAGE_SEND();
            await new Promise(resolve => {
                ChatEventManagerInstance.once(CHAT_EVENT_OPERATOR_SELECTED_MANUALY, data => {
                    resolve(data);
                });
            });
            this.operatorsSelectDisplayed.value = false;
            this.UNBLOCK_MESSAGE_SEND();
        }
    }

    isContactsFormFilled(): boolean | null {
        let fields = this.dialogStartForm.value.collectContacts;
        let result = true;
        for (let fieldName in fields) {
            if (fields.hasOwnProperty(fieldName)) {
                let value = ClientStore.getFieldValue(fieldName);
                if (!value) {
                    result = false;
                    break;
                }
            }
            }
        return result;
    }

    async askForContactsAfterStart() {
        if (!this.isContactsFormFilled()) {
            AnalyticCounters.send("Ask user contacts during chat", __('EVENT_SHOW_FORM_AFTER_DIALOG_START'));

            let filled = await this.askForContacts({
                blockMessageSend: false,
                allowAppendQuestionCategory: false,
                formType: FORM_TYPE_AFTER_CHAT_START
            });
            if (filled) {
                AnalyticCounters.send("User gave contacts during chat", __("EVENT_FILL_FORM_AFTER_DIALOG_START"));
            }
        }
    }

    async askForContactsBeforeStart(payload: { formType: string }) {
        if (!this.isContactsFormFilled()) {
            AnalyticCounters.send("Ask user contacts before chat", __("EVENT_SHOW_FORM_BEFORE_DIALOG"));

            let filled = await this.askForContacts({
                blockMessageSend: true,
                allowAppendQuestionCategory: true,
                formType: payload.formType
            });

            if (filled) {
                AnalyticCounters.send("User gave contacts before chat", __("EVENT_FILL_FORM_BEFORE_DIALOG"));
            }
        } else {
            await this.askForQuestionCategory();
        }
    }

    async askForQuestionCategory() {
        await this.askForContacts({
            blockMessageSend: true,
            allowAppendQuestionCategory: true,
            fields: {},
            formType: FORM_TYPE_QUESTION_CATEGORY
        });
    }

    toggleOfflineForm(value: boolean) {
        this.SET_OFFLINE_FORM_DISPLAYED(value);
    }

    private SHOW_RATE_REQUEST(messageId: number) {
        this.showRateRequestAfterMessage.value = messageId;
    }

    public showKeepOnlineMessageInHistoryAfterMessage(messageId: number) {
        if (!this.keepOnlineMessageInHistoryAfterMessage.value) {
            this.keepOnlineMessageInHistoryAfterMessage.value = messageId;
        }
    }

    private BLOCK_MESSAGE_SEND() {
        this.messageSendBlocked.value = true;
    }

    private UNBLOCK_MESSAGE_SEND() {
        this.messageSendBlocked.value = false;
    }

    private async askForContacts(payload: { formType?: string, blockMessageSend: boolean, allowAppendQuestionCategory: boolean, fields?: Dictionary<CollectContactFieldState> }) {
        let config = ConfigStore.siteParams.value.params;
        if (!payload.fields) {
            let startDialogFormConfig = payload.formType == FORM_TYPE_LEAD_GEN ? config.leadGen.startDialogFormConfig : this.dialogStartForm.value;
            payload.fields = startDialogFormConfig.collectContacts;
        }
        if (payload.allowAppendQuestionCategory && this.isNeedAddQuestionCategoryToFieldsList()) {
            payload.fields = {
                [QUESTION_CATEGORY_FIELD_NAME]: COLLECT_CONTACT_FIELD_REQUIRED,
                ...payload.fields
            };
        }
        let filled = false;

        for (let field in payload.fields) {
            if (payload.fields.hasOwnProperty(field)) {
                if (payload.fields[field] != COLLECT_CONTACT_FIELD_DISABLED) {
                    this.askForContactsFormType.value = payload.formType;
                    this.askForContactsIsKeepOnline.value = this.chatTabMode.value.isKeepOnlineMode;
                    this.askForContactsFields.value = payload.fields;
                    if (payload.blockMessageSend) {
                        this.BLOCK_MESSAGE_SEND();
                    }
                    filled = await new Promise((resolve) => {
                        ChatEventManagerInstance.once(CHAT_EVENT_CONTACTS_PROVIDED, data => {
                            resolve(data);
                        });
                    });
                    this.askForContactsFields.value = null;
                    this.askForContactsFormType.value = null;
                    this.UNBLOCK_MESSAGE_SEND();
                    break;
                }
            }
        }
        return filled;
    }

    private SET_OFFLINE_FORM_DISPLAYED(value: boolean) {
        this.offlineFormDisplayed.value = value;
    }

}

export const ChatTabStore = new ChatTab();

export interface WidgetDataInterface {
    isBotRunning?: boolean;
    cascadeMessageId?: number;
    clientMessageCountAtDialog: number;
    operatorMessageCountAtDialog: number;
    isDialogRunning: boolean;
    pinnedMessages: PinnedMessageInterface[];
    clientTagsHash: string[];
}

export interface PinnedMessageInterface {
    id: number;
    text: string;
}
