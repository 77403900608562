import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {defineAsyncComponent} from "vue";


const __default__ = {
    name: "ChatLightbox"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    visible: { type: Boolean },
    src: {}
  },
  emits: ["update:visible"],
  setup(__props: any, { emit: __emit }) {

let props = __props;


let emits = __emit;

let asyncLightbox = defineAsyncComponent(() => import(/* webpackChunkName: "lightbox" */ '~/core-ui/vue/vue-easy-lightbox/VueEasyLightbox.vue'));

return (_ctx: any,_cache: any) => {
  return (_ctx.visible && _ctx.src)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(asyncLightbox)), {
        key: 0,
        imgs: [_ctx.src],
        visible: _ctx.visible,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('update:visible', $event)))
      }, null, 40, ["imgs", "visible"]))
    : _createCommentVNode("", true)
}
}

})