import InlineContentWatcher from "~/chat/ts/service/InlineContentWatcher";
import {WidgetStore} from "~/chat/ts/store/Widget";
import WidgetContainer from "~/chat/vue/widget/WidgetContainer.vue";
import {App, createApp} from "vue";
import {CHAT_INLINE_MODE_CLASS} from "~/chat/ts/Constants";
import {ConfigStore} from "~/chat/ts/store/Config";
import {prepareChatApp} from "~/chat/ts/initialize/PrepareChatApp";

export default class InlineWidgetWatcher extends InlineContentWatcher {
    protected async getComponent(container: HTMLElement): Promise<App> {
        return prepareChatApp(createApp(WidgetContainer));
    }

    protected get selector(): string {
        return `#${ConfigStore.apiMethod.value}-container, #onlineSupportContainer`;
    }

    protected onContainersCountChange(count: number) {
        WidgetStore.SET_INLINE_MODE(count > 0);
    }

    protected get className(): string[] {
        return [CHAT_INLINE_MODE_CLASS];
    }

}