import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "text" }
const _hoisted_3 = {
  key: 0,
  class: "absolute-full-size loader d-flex align-items-center text-center"
}
const _hoisted_4 = {
  class: "w-100",
  style: {"font-size":"1.5em !important"}
}

import {computed} from 'vue';
import Dictionary from "~/ts/library/Dictionary";
import ChatLoader from "~/chat/vue/misc/ChatLoader.vue";
import {ConfigStore} from "~/chat/ts/store/Config";
import ChatFormButtonColorInterface from "~/chat/vue/widget/form/fields/ChatFormButtonColorInterface";


const __default__ = {
    name: "ChatFormButton"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    disabled: { type: Boolean, default: false },
    type: {},
    inverse: { type: Boolean, default: false },
    showLoad: { type: Boolean },
    round: { type: Boolean, default: false },
    color: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let id: number;
let startX: number;
let startY: number;
let touchStarted = false;

function onTouchDown(e: TouchEvent) {
    touchStarted = true;
    let touch = e.touches.item(0);
    id = touch.identifier;
    startX = touch.pageX;
    startY = touch.pageY;
}

function approximatelyEqual(a: number, b: number): boolean {
    return Math.abs(a - b) < 2;
}

function onTouchUp(e: TouchEvent) {
    let touch = e.changedTouches.item(0);
    if (id == touch.identifier) {
        if (approximatelyEqual(touch.pageX, startX) && approximatelyEqual(touch.pageY, startY)) {
            setTimeout(() => {
                emits("click");
            }, 10);
        }
    }
}

function onClick() {
    if (!touchStarted) {
        emits('click');
    }
}

let className = computed(() => {
    let result: Dictionary<boolean> = {
        inverse: props.inverse,
        load: props.showLoad,
        round: props.round,
        mobile: ConfigStore.isMobile.value
    };
    if (props.type) {
        result[props.type] = true;
    }
    return result
});

let isDisabled = computed(() => {
    return props.disabled || props.showLoad;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    disabled: _unref(isDisabled),
    onClick: onClick,
    onTouchstart: onTouchDown,
    onTouchend: onTouchUp,
    class: _normalizeClass(_unref(className)),
    style: _normalizeStyle(_ctx.color ? {
            color: `${_ctx.color.textColor} !important`,
            backgroundColor: `${_ctx.color.backgroundColor} !important`,
            borderColor: `${_ctx.color.backgroundColor} !important`
        } : null)
  }, [
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.showLoad)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(ChatLoader)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 46, _hoisted_1))
}
}

})