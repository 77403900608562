import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

import {computed} from 'vue';
import {ConfigStore} from "~/chat/ts/store/Config";
import {STATIC_SERVER} from "~/chat/ts/CommonConstants";


const __default__ = {
    name: "OperatorAvatar"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    src: { type: [String, Boolean] },
    name: {},
    withoutBorder: { type: Boolean },
    size: { default: '2em' }
  },
  setup(__props: any) {

let props = __props;

let isWithBorder = computed(() => {
    return !props.withoutBorder && ConfigStore.siteParams.value.params.designParams.operatorAvatarBorder;
});

let avatarSrc = computed(() => {
    return props.src ? props.src : STATIC_SERVER + "/images/support/noavatar.png";
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['operator-avatar', _unref(isWithBorder) ? 'with-border' : null]),
    style: _normalizeStyle({
            width: `${_ctx.size} !important`,
            height: `${_ctx.size} !important`
        })
  }, [
    _createElementVNode("img", {
      src: _unref(avatarSrc),
      alt: _ctx.name
    }, null, 8, _hoisted_1)
  ], 6))
}
}

})