import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

import {computed, ref} from 'vue';
import DraggableResizable from "~/cabinet/vue/interface/draggableResizable/DraggableResizable.vue";
import {WidgetStore} from "~/chat/ts/store/Widget";
import {WIDGET_MIN_HEIGHT, WIDGET_MIN_WIDTH} from "~/chat/ts/data/ISiteParams";
import {ConfigStore} from "~/chat/ts/store/Config";
import {ClientStore} from "~/chat/ts/store/Client";
import Events from "~/ts/library/Events";
import {addDisposableEvent} from "~/ts/vuePlugins/useDisposableEvent";



import WidgetLoader from "~/chat/vue/widget/WidgetLoader.vue";
import WidgetLoaderError from "~/chat/vue/widget/WidgetLoaderError.vue";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import Delay from "~/ts/library/Delay";
import {defineAsyncComponent} from "vue";


async function waitForOperatorStatus() {
    while (!OperatorsStore.isStatusInitialized.value) {
        await Delay.make(100);
    }
}

const __default__ = {
    name: "WidgetContainer",
    components: {
        WidgetInnerRoot: defineAsyncComponent({
            loader: (async () => {
                await waitForOperatorStatus();
                return import(/* webpackChunkName: "widget" */ '~/chat/vue/widget/WidgetInnerRoot.vue')
            }),
            loadingComponent: WidgetLoader,
            errorComponent: WidgetLoaderError,
            delay: OperatorsStore.isStatusInitialized.value ? 200 : 0,
            timeout: 60000
        })
    }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let textInputFocused = ref(false);

let classList = computed(() => {
    let result = ['online-chat-widget-draggable-wrapper', 'relative'];
    if (!isInline.value) {
        result.push('top-z-index');
        if (ConfigStore.isMobile.value) {
            result.push("touch");
        }
    }
    if (ConfigStore.hasContentBorderRadius.value) {
        result.push("has-content-border-radius");
    }
    if (textInputFocused.value) {
        result.push('text-input-focused');
    }
    return result;
});

let isBanned = ClientStore.isBanned;

let minWidth = computed(() => {
    return WIDGET_MIN_WIDTH;
});

let minHeight = computed(() => {
    return WIDGET_MIN_HEIGHT;
});

let mustBeInDom = computed(() => {
    return isOpened.value || WidgetStore.isOpenedOnce.value;
});

let designParams = computed(() => {
    return ConfigStore.siteParams.value.params.designParams;
});

let store = computed(() => {
    return WidgetStore;
});

let isOpened = computed(() => {
    return WidgetStore.isOpened.value;
});

let isDraggable = computed(() => {
    return !ConfigStore.isMobile.value && !isInline.value;
});

let isInline = WidgetStore.inlineMode;

function updatePosition(type: "left" | "top", value: number) {
    WidgetStore.updatePosition({type, value});
}

function updateSize(type: "width" | "height", value: number) {
    WidgetStore.updateSize({type, value});
}

let rootComponent = computed(() => {
    return isDraggable.value ? DraggableResizable : "div";
});

function onDragManualy() {
    WidgetStore.SET_POSITION_CHANGED_MANUALY();
}

addDisposableEvent(Events.addEventListener("focusin", document, e => {
    if (["input", "textarea"].includes((e.target as HTMLElement).tagName.toLowerCase())) {
        textInputFocused.value = true;
    }
}));
addDisposableEvent(Events.addEventListener("focusout", document, () => {
    textInputFocused.value = false;
}));


return (_ctx: any,_cache: any) => {
  const _component_widget_inner_root = _resolveComponent("widget-inner-root")!

  return (!_unref(isBanned))
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(rootComponent)), {
        key: 0,
        style: _normalizeStyle(_unref(isOpened) ? null : { pointerEvents: 'none !important' }),
        class: _normalizeClass(_unref(classList)),
        height: _unref(store).height.value,
        left: _unref(store).left.value,
        top: _unref(store).top.value,
        width: _unref(store).width.value,
        "onUpdate:top": _cache[0] || (_cache[0] = ($event: any) => (updatePosition('top', $event))),
        "onUpdate:left": _cache[1] || (_cache[1] = ($event: any) => (updatePosition('left', $event))),
        "onUpdate:width": _cache[2] || (_cache[2] = ($event: any) => (updateSize('width', $event))),
        "onUpdate:height": _cache[3] || (_cache[3] = ($event: any) => (updateSize('height', $event))),
        onDragManualyOnce: onDragManualy,
        "min-height": _unref(minHeight),
        "min-width": _unref(minWidth),
        resizable: _unref(designParams).resizable,
        fixed: "",
        "drag-handler": _unref(designParams).draggable ? '.widget-header' : '.widget-drag-disabled'
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, { name: "zoom" }, {
            default: _withCtx(() => [
              (_unref(mustBeInDom))
                ? _withDirectives((_openBlock(), _createBlock(_component_widget_inner_root, {
                    key: 0,
                    class: "online-chat-widget-container online-chat-widget-container-border-radius",
                    style: _normalizeStyle({
                    display: _unref(isOpened) ? undefined : 'none !important'
                })
                  }, null, 8, ["style"])), [
                    [_vShow, _unref(isOpened)]
                  ])
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 40, ["style", "class", "height", "left", "top", "width", "min-height", "min-width", "resizable", "drag-handler"]))
    : _createCommentVNode("", true)
}
}

})