import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import WidgetLoaderWrapper from "~/chat/vue/widget/WidgetLoaderWrapper.vue";
import {__} from "~/ts/library/Translate";

const __default__ = {
    name: "WidgetLoaderError"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(WidgetLoaderWrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString(_unref(__)("ERROR")), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(__)("TRY_TO_REFRESH_PAGE")), 1)
    ]),
    _: 1
  }))
}
}

})