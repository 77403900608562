import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "classic-trigger-badge" }
const _hoisted_2 = { class: "text trigger-font-family" }

import Dictionary from "~/ts/library/Dictionary";
import {
    TRIGGER_SIDE_BOTTOM,
    TRIGGER_SIDE_CENTER,
    TRIGGER_SIDE_LEFT,
    TRIGGER_SIDE_RIGHT,
    TRIGGER_SIDE_TOP
} from "~/chat/ts/data/ISiteParams";
import SvgIcon from "~/chat/vue/misc/SvgIcon.vue";
import TriggerDropdownContainer from "~/chat/vue/trigger/TriggerDropdownContainer.vue";
import TriggerUnreadedCount from "~/chat/vue/trigger/TriggerUnreadedCount.vue";
import ChatStyle from "~/chat/vue/misc/ChatStyle.vue";
import {USE_TRIGGER_EMITS, useTrigger, useTriggerProps} from "~/chat/vue/trigger/useTrigger";
import {computed} from "vue";


const __default__ = {
    name: "ClassicTrigger"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useTriggerProps()
},
  emits: ["update:additional-styles", "default-bubble-text", "updated"],
  setup(__props, { emit: __emit }) {

let props = __props;

let emits = __emit

let {text, icon} = useTrigger(props, {
    isBubbleTextAvailable: () => false
});

let outerStyle = computed(() => {
    let config = props.config;

    let primarySide = config.offset.primary.side;
    let secondarySize = config.offset.secondary.side;
    let transform: string;
    let transformOrigin: string;
    let additionalStyles: Dictionary<string> = {};
    if (primarySide == TRIGGER_SIDE_LEFT) {
        if (secondarySize == TRIGGER_SIDE_BOTTOM) {
            transform = "rotateZ(-90deg) ";
            transformOrigin = "left top";
            additionalStyles["transform"] = "translateY(100%) !important";
        } else if (secondarySize == TRIGGER_SIDE_CENTER) {
            additionalStyles["transform"] = "none";
            transform = "rotateZ(-90deg) translateX(-50%)";
            transformOrigin = "left top";
        } else {
            transform = "rotateZ(-90deg) translateX(-100%)";
            transformOrigin = "left top";
        }
    } else if (primarySide == TRIGGER_SIDE_RIGHT) {
        if (secondarySize == TRIGGER_SIDE_TOP) {
            transform = "rotateZ(-90deg) translateY(-100%)";
            transformOrigin = "right top";
        } else if (secondarySize == TRIGGER_SIDE_CENTER) {
            transform = "rotateZ(-90deg) translateY(-100%) translateX(50%)";
            transformOrigin = "right top";
            additionalStyles["transform"] = "none";
        } else {
            transform = "rotateZ(-90deg) translateX(100%)";
            transformOrigin = "right bottom";
        }
    }

    let result: Dictionary<string> = {
        transform: transform ? transform + " !important" : null,
        transformOrigin: transformOrigin ? transformOrigin + " !important" : null
    };

    emits("update:additional-styles", additionalStyles);

    return result;
});

let swapBorderTop = computed(() => {
    return [TRIGGER_SIDE_TOP, TRIGGER_SIDE_LEFT].indexOf(props.config.offset.primary.side) != -1
});

let innerStyle = computed(() => {
    let config = props.config;
    let radius = `${config.radius}px`;

    let result: Dictionary<Dictionary<string>> = {
        '.trigger-classic': {
            borderRadius: (swapBorderTop.value ? `0 0 ${radius} ${radius}` : `${radius} ${radius} 0 0`),
            [swapBorderTop.value ? 'borderBottom' : 'borderTop']: `${config.borderWidth}px solid rgba(0,0,0,.15)`
        }
    };

    return result;
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_unref(outerStyle))
  }, [
    _createVNode(TriggerDropdownContainer, { class: "classic-dropdown-container" }),
    _createElementVNode("div", {
      class: _normalizeClass({
                'trigger-background trigger-classic trigger-font-size': true,
                'icon-hidden': !!_unref(props).config.isIconHidden
            })
    }, [
      _createVNode(ChatStyle, {
        css: _unref(innerStyle),
        id: "trigger-classic-inner"
      }, null, 8, ["css"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TriggerUnreadedCount)
      ]),
      (!_unref(props).config.isIconHidden)
        ? (_openBlock(), _createBlock(SvgIcon, {
            key: 0,
            class: "icon",
            "icon-name": _unref(icon).name,
            "icon-style": _unref(icon).class
          }, null, 8, ["icon-name", "icon-style"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(text)), 1)
    ], 2)
  ], 4))
}
}

})