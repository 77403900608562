import CrossTabSender from "~/ts/library/crossTabCommunication/talk/CrossTabSender";
import Events from "~/ts/library/Events";
import InitVue from "~/chat/ts/initialize/InitVue";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import {ConfigStore} from "~/chat/ts/store/Config";
import {ClientStore} from "~/chat/ts/store/Client";
import "~/chat/scss/index.scss";
import HashString from "~/ts/library/HashString";
import Api from "~/chat/ts/service/api/Api";
import Filters from "~/chat/ts/service/autoActions/Filters";
import AutoActions from "~/chat/ts/service/autoActions/AutoActions";
import InitializeFilters from "~/chat/ts/service/autoActions/InitializeFilters";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import ReviewsOnSite from "~/chat/ts/service/reviews/ReviewsOnSite";
import ChatApiResult from "~/chat/ts/service/request/ChatApiResult";
import Dictionary from "~/ts/library/Dictionary";
import {WidgetStore} from "~/chat/ts/store/Widget";
import InlineTabsWatcher from "~/chat/ts/service/InlineTabsWatcher";
import ApiCallback, {
    API_CALLBACK_FULLY_LOADED,
    API_CALLBACK_OPEN_SUPPORT
} from "~/chat/ts/service/api/methods/ApiCallback";
import InitListener from "~/chat/ts/initialize/InitListener";
import ClientActivityInstance from "~/chat/ts/service/ClientActivity";
import ISiteParams, {TRIGGER_TYPE_DISABLE} from "~/chat/ts/data/ISiteParams";
import InitConfiguration from "~/chat/ts/initialize/InitConfiguration";
import {CHAT_CONFIG_QUEUE_KEY, isInternationalVersionUsed} from "~/chat/ts/CommonConstants";
import {IChatConfigQueue} from "~/chat/ts/IChatConfigQueue";
import {ISetup} from "~/chat/ts/data/ISetup";
import AnalyticCounters from "~/chat/ts/service/AnalyticCounters";
import setClientInfo from "~/chat/ts/service/api/methods/SetClientInfo";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import Delay from "~/ts/library/Delay";
import {TriggerStore} from "~/chat/ts/store/Trigger";
//import initChatSentry from "~/chat/ts/InitChatSentry";
import {Intervals} from "~/ts/library/delay/Intervals";
import TrackIsOnTab from "~/ts/library/crossTabCommunication/TrackIsOnTab";
import FieldAutoFiller from "~/chat/ts/service/FieldAutoFiller";
import {toggleInternationHost} from "~/core-ui/ts/Constants";
import IntegrationLinks from "~/chat/ts/service/IntegrationLinks";
import {ComponentPublicInstance} from "vue";


async function continueLoadOnlineChat(configurations: Dictionary<ISiteParams>, setup: ISetup) {
    let myWindow = window as any;
    ConfigStore.SET_API_METHOD(setup.apiMethod);

    function isListenersMustAutoStart() {
        return !ConfigStore.siteParams.value.isStartListenOnOpenWidget || MessageStorage.messagesCount > 0 || WidgetStore.isOpened.value;
    }

    toggleInternationHost(isInternationalVersionUsed());

    async function initListenersAndActivity(isNewVisit: boolean) {
        await CrossTabSender.init();
        if (isListenersMustAutoStart()) {
            await initListeners(isNewVisit);
        } else {
            ApiCallback.setCallback(API_CALLBACK_OPEN_SUPPORT, () => {
                initListeners(isNewVisit);
            }, true);
        }

        Filters.init();
        InitializeFilters.make();
        await AutoActions.init();
        FieldAutoFiller.init();

        function onUrlChange() {
            ClientActivityInstance.start(false);
        }


        Events.addEventListener("popstate", window, () => {
            onUrlChange();
        });


        let prevSendedUrl: string;
        Intervals.set(() => {
            if (TrackIsOnTab.get()) {
                let checkUrl = window.location.href.split("#")[0];
                if (checkUrl !== prevSendedUrl) {
                    let set = prevSendedUrl != null;
                    prevSendedUrl = checkUrl;
                    if (set) {
                        onUrlChange();
                    }
                }
            }
        }, 1000);
    }

    async function initListeners(isNewVisit: boolean) {
        await InitListener.init();
        ClientActivityInstance.start(isNewVisit);
    }

    await Events.ready();
    if (await InitConfiguration.init(configurations, setup)) {

        //initChatSentry();

        /*
        if (ClientStore.id == "AyBCBzTSbGMmFlKs1TFuywOrMMttAOBp") {
            throw new Error("test error");
        }
         */

        if (TriggerStore.triggerParams.value.triggerType != TRIGGER_TYPE_DISABLE) {
            let hash = HashString.make(`${ConfigStore.siteId.value}/${ClientStore.id.value}`);
            StorageInstance.setPrefix(`Chat/${hash}`);

            let isNewVisit = await ClientActivityInstance.initVisitId();
            //console.log("Это новый визит?", isNewVisit);

            OperatorsStore.initOperatorsStatusFromCache();
            //Добавил задержку, чтобы посмотреть - нет ли автодействий с уничтожением виджета. Если есть - на время скрывается ярлык, до первого запуска автодействий
            await initListenersAndActivity(isNewVisit);

            let app = InitVue.init();

            (new ReviewsOnSite()).watch();
            (new InlineTabsWatcher()).watch();
            IntegrationLinks.init();

            //вызываем после vue, чтобы openSupport позиционировался верно

            Api.init();


            if (ConfigStore.siteParams.value.integrations.roistat) {
                detectRoistat();
            }

            if (!myWindow.$SA && !myWindow.supportAPI) {
                myWindow.$SA = myWindow.supportAPI = Api.methods;
            }

            ApiCallback.emit(API_CALLBACK_FULLY_LOADED);


            showPaymentAlerts();


            /*
            let locker = new CrossTabLock("foo", 10000);
            if (await locker.lock()) {
                console.log("lock success");
            } else {
                console.log("lock failed");
            }

             */
        }
    }
}

let configurations: IChatConfigQueue = (window as any)[CHAT_CONFIG_QUEUE_KEY];
if (configurations && configurations.length) {
    let value = configurations.splice(0, 1)[0];
    let continueLoad = () => continueLoadOnlineChat(value.configurations, value.setup);
    if (document.body) {
        continueLoad();
    } else {
        document.addEventListener("DOMContentLoaded", () => document.body ? continueLoad() : null);
    }
}

function showPaymentAlerts(vue?: ComponentPublicInstance) {
    let messages: Dictionary<{ code: number, descr: string }> = {
        "chat-payment-success": {code: 1, descr: "Ваш платёж успешно принят"},
        "chat-payment-fail": {code: 0, descr: "Процедура оплаты завершилась неудачно"}
    };
    for (let key in messages) {
        if (messages.hasOwnProperty(key)) {
            let hash = window.location.hash;
            if (hash && hash.includes(key)) {
                WidgetStore.open();
                (new ChatApiResult(messages[key])).showMessage(null);
                break;
            }
        }
    }
}

async function detectRoistat() {
    let roistatVisit = AnalyticCounters.getRoistatVisit();

    if (roistatVisit != null) {
        setClientInfo({
            custom: {
                roistat_visit: roistatVisit
            }
        });
    } else {
        await Delay.make(1000);
        detectRoistat();
    }
}