import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"

import TriggerDropdownContainer from "~/chat/vue/trigger/TriggerDropdownContainer.vue";
import RoundTriggerInner from "~/chat/vue/trigger/RoundTriggerInner.vue";
import {ConfigStore} from "~/chat/ts/store/Config";
import {TriggerStore} from "~/chat/ts/store/Trigger";
import {TRIGGER_MENU_HIDE_CLICK} from "~/chat/ts/data/ISiteParams";
import RoundTriggerBadge from "~/chat/vue/trigger/RoundTriggerBadge.vue";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";
import {STATIC_SERVER} from "~/chat/ts/CommonConstants";
import {USE_TRIGGER_EMITS, useTrigger, useTriggerProps} from "~/chat/vue/trigger/useTrigger";
import {computed} from "vue";
import {DEVICE_DESKTOP} from "~/ts/library/Device";
import GradientPickerHelper from "~/core-ui/vue/ui/gradient-picker/GradientPickerHelper";


const __default__ = {
    name: "RoundTrigger"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useTriggerProps()
},
  emits: ["default-bubble-text", "updated"],
  setup(__props, { emit: __emit }) {

let props = __props;

let emits = __emit;

let {defaultBubbleText, icon} = useTrigger(props, {
    isBubbleTextAvailable: () => !!props.config.roundTextEnabled
});

let isDesktop = computed(() => ConfigStore.device.value == DEVICE_DESKTOP);

let designParams = computed(() => {
    return ConfigStore.siteParams.value.params.designParams;
});

let isCloseByClick = computed(() => {
    return TriggerStore.triggerMenuDisplayOptions.value.hide == TRIGGER_MENU_HIDE_CLICK;
});

let isMenuOpened = TriggerStore.isTriggerMenuOpened;

let triggerBackground = computed(() => {
    return GradientPickerHelper.getOneColor(designParams.value.triggerBackground);
});

let operatorAvatar = computed(() => {
    if (!props.config.disallowAvatarOnRoundTrigger) {
        if (ChatTabStore.chatTabMode.value.bot) {
            let avatar = ConfigStore.siteParams.value.params.bot.avatar;
            if (avatar) {
                if (avatar.indexOf("://") === -1) {
                    avatar = STATIC_SERVER + avatar;
                }
                return avatar;
            }
        }

        let operator = OperatorsStore.selected.value.operator;
        if (operator && operator.avatar) {
            return operator.avatar;
        }
    }
    return null;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, {
      name: "rotate-z",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_unref(isMenuOpened))
          ? (_openBlock(), _createBlock(RoundTriggerInner, {
              key: "close",
              "icon-name": _unref(isCloseByClick) ? 'times' : 'ellipsis-h'
            }, null, 8, ["icon-name"]))
          : (_openBlock(), _createBlock(RoundTriggerInner, {
              key: "open",
              "icon-name": _unref(icon).name,
              "icon-style": _unref(icon).class,
              img: _unref(operatorAvatar),
              class: _normalizeClass({[_unref(props).config.roundTriggerAnimation]: true, 'is-desktop': _unref(isDesktop)}),
              style: _normalizeStyle({
                    '--trigger-background': _unref(triggerBackground),
                })
            }, {
              default: _withCtx(() => [
                _createVNode(RoundTriggerBadge)
              ]),
              _: 1
            }, 8, ["icon-name", "icon-style", "img", "class", "style"]))
      ]),
      _: 1
    }),
    _createVNode(TriggerDropdownContainer, {
      "default-bubble-text": _unref(defaultBubbleText),
      class: "dropdown"
    }, null, 8, ["default-bubble-text"])
  ]))
}
}

})