import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

import {Component, computed} from 'vue';
import {
    ITriggerMenuTypeId,
    TRIGGER_MENU_TYPE_DROPDOWN,
    TRIGGER_MENU_TYPE_MOBILE,
    TRIGGER_MENU_TYPE_ROUND
} from "~/chat/ts/data/ISiteParams";
import MobileTriggerMenu from "~/chat/vue/trigger/menu/MobileTriggerMenu.vue";
import RoundTriggerMenu from "~/chat/vue/trigger/menu/RoundTriggerMenu.vue";
import DropdownTriggerMenu from "~/chat/vue/trigger/menu/DropdownTriggerMenu.vue";
import {TriggerStore} from "~/chat/ts/store/Trigger";


const __default__ = {
    name: "TriggerMenu"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    triggerWidth: {},
    allowedDisplayTypes: {},
    disallowedDisplayTypes: {}
  },
  setup(__props: any) {

let props = __props;

let isMenuOpened = TriggerStore.isTriggerMenuOpened;

function onClickaway() {
    setTimeout(() => TriggerStore.SET_TRIGGER_MENU_OPENED(false), 200);
}

let isAllowed = computed(() => {
    let displayType = TriggerStore.triggerMenuDisplayTypeId.value;
    if (displayType) {
        if (props.allowedDisplayTypes && props.allowedDisplayTypes.indexOf(displayType) == -1) {
            return false;
        }

        if (props.disallowedDisplayTypes && props.disallowedDisplayTypes.indexOf(displayType) > -1) {
            return false;
        }
        return true;

    }
    return false;
});

let hasMenu = TriggerStore.hasMenu;

let menuComponent = computed<Component>(() => {
    let triggerMenuTypeId = TriggerStore.triggerMenuDisplayTypeId.value;
    if (triggerMenuTypeId) {
        if (triggerMenuTypeId === TRIGGER_MENU_TYPE_ROUND) {
            return RoundTriggerMenu;
        } else if (triggerMenuTypeId === TRIGGER_MENU_TYPE_DROPDOWN) {
            return DropdownTriggerMenu;
        } else if (triggerMenuTypeId === TRIGGER_MENU_TYPE_MOBILE) {
            return MobileTriggerMenu;
        }
    }
    return null;
});


return (_ctx: any,_cache: any) => {
  const _directive_on_native = _resolveDirective("on-native")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createBlock(_Transition, { name: "trigger-menu" }, {
    default: _withCtx(() => [
      (_unref(hasMenu) && _unref(isMenuOpened) && _unref(menuComponent) && _unref(isAllowed))
        ? _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuComponent)), {
            key: 0,
            "trigger-width": _ctx.triggerWidth
          }, null, 8, ["trigger-width"])), [
            [
              _directive_on_native,
              void 0,
              "click",
              { stop: true }
            ],
            [_directive_click_away, onClickaway]
          ])
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})