import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import SvgIcon from "~/chat/vue/misc/SvgIcon.vue";

const __default__ = {
    name: "ChatLoader"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(SvgIcon, {
    class: "spin",
    "icon-name": "circle-notch"
  }))
}
}

})