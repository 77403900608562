import AbstractAutoAction, {
    IAutoActionSuccessRunResult
} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {
    ACTION_FILTER_AUTO_MESSAGE_TO_CLIENT,
    ACTION_FILTER_AUTO_MESSAGE_TO_CLIENT_AT_VISIT,
    ACTION_FILTER_CITY_LANG,
    ACTION_FILTER_IP,
    ACTION_FILTER_TIME_FROM_LAST_AUTO_MESSAGE_TO_CLIENT,
    ACTION_FILTER_UTM_CONTENT,
    ACTION_FILTER_UTM_TERM
} from "~/chat/ts/data/AutoActions";
import IMessage, {MESSAGE_TYPE_AUTO_MESSAGE} from "~/chat/ts/data/chat/IMessage";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import MessageListener from "~/chat/ts/service/listen/MessageListener";
import Delay from "~/ts/library/Delay";
import {MESSAGE_HTML_BLOCK_PREFIX, MESSAGE_HTML_PREFIX} from "~/chat/ts/Constants";
import {IOperator, IOperatorGroup} from "~/chat/ts/data/IOperator";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import Random from "~/ts/library/Random";
import Filters from "~/chat/ts/service/autoActions/Filters";
import {CLIENT_FIELD_DESCR, ClientStore} from "~/chat/ts/store/Client";
import Dictionary from "~/ts/library/Dictionary";
import ChatDateHelper from "~/chat/ts/service/ChatDateHelper";
import AnalyticCounters from "~/chat/ts/service/AnalyticCounters";
import {__} from "~/ts/library/Translate";
import {ConfigStore} from "~/chat/ts/store/Config";
import {DEVICE_DESKTOP, DEVICE_MOBILE} from "~/ts/library/Device";
import {
    ISendTextMessageToClientParams,
    ISendTextMessageToClientSuccess,
    TEXT_ITEM_TYPE_HTML,
    TextItem
} from "~/chat/ts/service/autoActions/actions/Interfaces";
import {
    NO_BADGE_DESKTOP,
    NO_BADGE_MOBILE,
    SENDER_TYPE_ANY,
    SENDER_TYPE_ONLY_GROUP,
    SENDER_TYPE_ONLY_OPERATOR,
    SENDER_TYPE_PRIORITY_GROUP,
    SENDER_TYPE_PRIORITY_OPERATOR,
    SENDER_TYPE_VIRTUAL,
    SILENT_DESKTOP,
    SILENT_MOBILE
} from "~/chat/ts/service/autoActions/actions/Constants";
import Url from "~/ts/library/Url";


interface ISendArrayItem {
    text: string,
    item: TextItem,
    clientSideId: string
}

export default class SendTextMessageToClient extends AbstractAutoAction<ISendTextMessageToClientParams> {
    private sendResultToServer: boolean;

    constructor(params: ISendTextMessageToClientParams, sendResultToServer: boolean = true) {
        super(params);
        this.sendResultToServer = sendResultToServer;
    }

    public isNeedSendResultToServer(): boolean {
        return this.sendResultToServer;
    }

    get isNoize(): boolean {
        return true;
    }

    async run(): Promise<IAutoActionSuccessRunResult[]> {
        let result: ISendTextMessageToClientSuccess[] = [];
        let operator = this.getOperator(OperatorsStore.balanceType.value.selectRandomOperator);
        if (operator) {
            let sendArray: ISendArrayItem[] = [];
            for (let i = 0; i < this.params.text.length; i++) {
                let item = this.params.text[i];
                let text = this.getText(item, operator);
                if (text != null) {
                    let clientSideId = Random.uid();
                    sendArray.push({
                        text: text,
                        item: item,
                        clientSideId
                    });
                    result.push({
                        text: text,
                        operator: operator.fio,
                        operatorUid: operator.virtual ? null : operator.uid,
                        timeout: sendArray.length > 1 ? item.delay : 0,
                        clientSideId
                    });
                }
            }

            (async () => {
                this.removeNotAnsweredAutoMessages(sendArray);
                for (let i = 0; i < sendArray.length; i++) {
                    let lastClientMessageId = MessageStorage.lastClientMessageId;
                    let item = sendArray[i].item;
                    let text = sendArray[i].text;

                    if (i > 0) {
                        await this.delayAfterPreviousMessage(item);
                    }
                    await this.simulateTyping(operator);
                    if (this.params.stopSendingIfClientAnswers && lastClientMessageId != MessageStorage.lastClientMessageId) {
                        break;
                    }
                    await this.receiveMessage(operator, text, i == sendArray.length - 1, sendArray[i].clientSideId);
                }
            })();
        }
        if (result.length) {
            Filters.incr(ACTION_FILTER_AUTO_MESSAGE_TO_CLIENT_AT_VISIT);
            Filters.incr(ACTION_FILTER_AUTO_MESSAGE_TO_CLIENT);
            Filters.set(ACTION_FILTER_TIME_FROM_LAST_AUTO_MESSAGE_TO_CLIENT);
            AnalyticCounters.send("Automessage shown", __('EVENT_RECEIVE_AUTO_MESSAGE'));
        }
        return result;
    }

    public static removeNotAnsweredAutoMessages(removePreviousNotAnsweredAutoMessages: boolean, sendArray?: ISendArrayItem[]) {
        let messageIdList = MessageStorage.messageIdList;
        for (let i = messageIdList.length - 1; i >= 0; i--) {
            let id = messageIdList[i];
            let message = MessageStorage.getMessage(id);
            if (message) {
                if (!message.messageType) {
                    return;
                }
                if (message.messageType == MESSAGE_TYPE_AUTO_MESSAGE) {
                    //удаляем только автодействия с таким же текстом
                    if (sendArray) {
                        for (let item of sendArray) {
                            if (message.text === item.text || message.visitId != ConfigStore.visitId.value || removePreviousNotAnsweredAutoMessages) {
                                MessageStorage.removeMessage(id);
                                //return;
                            }
                        }
                    } else if (removePreviousNotAnsweredAutoMessages) {
                        MessageStorage.removeMessage(id);
                    }
                }
            }
        }
    }

    private removeNotAnsweredAutoMessages(sendArray: ISendArrayItem[]) {
        SendTextMessageToClient.removeNotAnsweredAutoMessages(this.params.removePreviousNotAnsweredAutoMessages, sendArray);
    }

    isCanRunNow(): boolean {
        let operator = this.getOperator(false);
        let result = operator != null;
        if (result) {
            for (let item of this.params.text) {
                if (this.getText(item, operator) == null) {
                    result = false;
                } else {
                    result = true;
                    break;
                }
            }
        }
        return result;
    }

    private isShowTyping(): boolean {
        return !!(this.isShowNotification() && this.params.typing);
    }

    initParams() {
        let params = this.params;
        if (params.senderType == SENDER_TYPE_VIRTUAL) {
            let sender = params.sender;
            if (sender) {
                let operator: IOperator = OperatorsStore.getVirtualOperator(sender, ConfigStore.siteParams.value.params.operators.balance.smart.avatar)

                ConfigStore.siteParams.value.operators[sender] = operator;
            }
        }
    }

    private isShowNotification(): boolean {
        let value = ConfigStore.device.value == DEVICE_DESKTOP ? SILENT_DESKTOP : SILENT_MOBILE;
        return !this.params.notify || this.params.notify.indexOf(value as any) == -1;
    }

    private isPlaySound(): boolean {
        let value = this.isShowNotification();
        if (this.params.isPlaySound) {
            value = this.params.isPlaySound[ConfigStore.device.value == DEVICE_DESKTOP ? DEVICE_DESKTOP : DEVICE_MOBILE];
        }
        return value;
    }

    private isShowBadge() {
        if (this.params.hiddenText) {
            return false;
        }
        let value = ConfigStore.device.value == DEVICE_DESKTOP ? NO_BADGE_DESKTOP : NO_BADGE_MOBILE;
        return !this.params.notify || this.params.notify.indexOf(value as any) == -1;
    }

    private getOperator(remember: boolean = true): IOperator | null {
        let operator: IOperator = null;
        let group: IOperatorGroup = null;
        let operators = OperatorsStore.visibleOperators.value;
        operators = operators.length ? operators : OperatorsStore.operatorsList.value;
        if (operators.length) {
            let senderType = this.params.senderType;
            operator = OperatorsStore.selected.value.operator;
            if (!operator) {
                let currentGroup = OperatorsStore.selected.value.group;
                if (currentGroup) {
                    let visibleOperatorsOfGroup = OperatorsStore.getVisibleOperatorsOfGroup(currentGroup);
                    if (visibleOperatorsOfGroup.length) {
                        operator = Random.randomArrayElement(visibleOperatorsOfGroup);
                    }
                }
                if (!operator) {
                    operator = Random.randomArrayElement(operators);
                }
            }
            if (senderType != SENDER_TYPE_ANY) {
                let sender = this.params.sender;
                if (senderType == SENDER_TYPE_ONLY_OPERATOR || senderType == SENDER_TYPE_PRIORITY_OPERATOR) {
                    let priorityOperator = operators.find(operator => operator.id == sender);
                    if (priorityOperator) {
                        operator = priorityOperator;
                    } else if (senderType == SENDER_TYPE_ONLY_OPERATOR) {
                        operator = null;
                    }
                } else if (senderType == SENDER_TYPE_ONLY_GROUP || senderType == SENDER_TYPE_PRIORITY_GROUP) {
                    group = OperatorsStore.groups.value[sender];
                    if (group) {
                        if (!OperatorsStore.isOperatorInGroup(operator, group)) {
                            let groupOperator = Random.randomArrayElement(OperatorsStore.getVisibleOperatorsOfGroup(group));
                            if (groupOperator) {
                                operator = groupOperator;
                            } else if (senderType == SENDER_TYPE_ONLY_GROUP) {
                                operator = null;
                            }
                        }
                    }
                } else if (senderType == SENDER_TYPE_VIRTUAL) {
                    if (sender) {
                        operator = OperatorsStore.getOperatorByLogin(sender);
                    }
                }
            }
            if (remember && operator) {
                OperatorsStore.setSelectedOperator(operator, group);
            }
        }
        return operator;
    }

    private generateId() {
        let maxId = MessageStorage.maxId;
        maxId = maxId ? maxId : 0;
        return maxId + parseFloat("0." + Date.now().toString().substr(5));
    }

    public static replaceTextVariables(text: string, operator?: IOperator, replaceOnEmptyString: boolean = false): string | null {
        if (typeof text == "string") {
            let variables = this.getVariablesForText(text, operator);
            for (var key in variables) {
                if (variables.hasOwnProperty(key)) {
                    let placeholder = "[" + key + "]";
                    if (text.indexOf(placeholder) > -1) {
                        if (!variables[key]) {
                            if (!replaceOnEmptyString) {
                                return null;
                            }
                            variables[key] = '';
                        }
                        text = text.split('[' + key + ']').join(variables[key]);
                    }
                }
            }
        }
        return text;
    }

    private static getVariablesForText(text: string, operator?: IOperator): Dictionary<string> {
        let url = window.location;
        let port = url.port;
        if (port.length) {
            port = ":" + port;
        }

        let h1 = document.querySelector("h1");

        if (!operator) {
            operator = OperatorsStore.selected.value.operator;
        }


        let result: Dictionary<string> = {
            siteAddress: url.protocol + "//" + url.host + port,
            currentPath: (new Url(window.location.href)).path,
            title: document.title,
            h1: h1 ? h1.innerText : null,
            searchKeyword: null,//Filters.get(ACTION_FILTER_SEARCH_KEYWORD),
            city: Filters.get(ACTION_FILTER_CITY_LANG),
            ip: Filters.get(ACTION_FILTER_IP),
            name: ClientStore.getFieldValue(CLIENT_FIELD_DESCR),
            utm_content: Filters.get(ACTION_FILTER_UTM_CONTENT),
            utm_term: Filters.get(ACTION_FILTER_UTM_TERM),
            operator: operator?.fio
        };
        let formElements = ConfigStore.siteParams.value.formElements;
        for (let key in formElements) {
            if (formElements.hasOwnProperty(key)) {
                let element = formElements[key];
                result[`client_${element.name}`] = ClientStore.getFieldValue(element.name);
            }
        }

        let regexp = /\[(dom={(.*?)})?]/gm;
        let regexpResult;
        while (regexpResult = regexp.exec(text)) {
            if (regexpResult[1] && regexpResult[2]) {
                let content: HTMLElement = document.querySelector(regexpResult[2]);
                result[regexpResult[1]] = content ? content.innerText : null;
            }
        }

        return result;
    }

    private async receiveMessage(operator: IOperator, text: string, isLast: boolean, clientSideId: string) {
        let id = this.generateId();
        var dt = (new ChatDateHelper(new Date())).convertToServer().toMysqlFormat();
        let message: IMessage = {
            id,
            from: operator.id,
            operatorDescr: operator.fio,
            text,
            messageType: MESSAGE_TYPE_AUTO_MESSAGE,
            dt: dt,
            other: {
                clientSideId,
                ttlSeconds: this.params.ttlEnabled && this.params.ttlSeconds > 0 ? this.params.ttlSeconds : undefined
            }
        };
        if (isLast) {
            if (this.params.suggests && this.params.suggests.length) {
                message.other.buttons = this.params.suggests;
                message.other.blockTextInput = !!this.params.blockTextInputWithSuggests;
            }
        }
        if (this.params.hiddenText) {
            message.other.hiddenText = true;
        }
        let listener = new MessageListener();
        if (!this.isShowNotification()) {
            message.hideNotification = true;
        }
        listener.setPlaySound(this.isPlaySound())

        if (!this.isShowBadge()) {
            message.hideBadge = true;
        }
        //let listener: AbstractReceiveMessageListener = this.isShowNotification() ? new MessageListener() : new AddToHistoryListener();
        listener.onAction(message);
    }

    private async delayAfterPreviousMessage(item: TextItem) {
        if (item.delay > 0) {
            await Delay.make(item.delay * 1000);
        }
    }

    private async simulateTyping(operator: IOperator) {
        if (this.isShowTyping()) {
            MessageStorage.setOperatorTyping({
                operatorId: operator.id
            });
            await Delay.make(3000);
        }
    }

    private getText(item: TextItem, operator: IOperator): string | null {
        let text = item.text;
        if (typeof text == "string") {
            text = SendTextMessageToClient.replaceTextVariables(text, operator);
            if (typeof text === "string") {
                if (item.type == TEXT_ITEM_TYPE_HTML) {
                    let prefix = MESSAGE_HTML_PREFIX;
                    if (item.notInBubble) {
                        prefix = MESSAGE_HTML_BLOCK_PREFIX;
                    }
                    text = prefix + text;
                }
            }
        }
        return text;
    }

}

