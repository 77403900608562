import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "round-trigger-badge" }

import TriggerUnreadedCount from "~/chat/vue/trigger/TriggerUnreadedCount.vue";

const __default__ = {
    name: "RoundTriggerBadge"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TriggerUnreadedCount)
  ]))
}
}

})