import Filters from "~/chat/ts/service/autoActions/Filters";
import {
    ACTION_FILTER_AUTO_MESSAGE_TO_CLIENT_AT_VISIT,
    ACTION_FILTER_CLICK_ON_ELEMENT,
    ACTION_FILTER_CLIENT_MESSAGES_COUNT_AT_VISIT,
    ACTION_FILTER_CLOSE_WIDGET,
    ACTION_FILTER_COL_OPERATORS_ONLINE,
    ACTION_FILTER_COL_OPERATORS_ONLINE_AND_VISIBLE,
    ACTION_FILTER_CURRENT_PAGE_TITLE,
    ACTION_FILTER_DEVICE,
    ACTION_FILTER_ELEMENT_ON_PAGE,
    ACTION_FILTER_IS_TARGET_CLIENT,
    ACTION_FILTER_MESSAGES_COUNT_AT_VISIT,
    ACTION_FILTER_MOUSE_LEAVE_PAGE,
    ACTION_FILTER_OPERATOR_MESSAGES_COUNT_AT_VISIT,
    ACTION_FILTER_OPERATOR_ONLINE,
    ACTION_FILTER_OPERATOR_ONLINE_AND_VISIBLE,
    ACTION_FILTER_PAGE_VISITED,
    ACTION_FILTER_PAGES_AT_VISIT,
    ACTION_FILTER_PREVIOUS_URL,
    ACTION_FILTER_REFERER,
    ACTION_FILTER_SCROLL_PERCENTAGE,
    ACTION_FILTER_SCROLL_PX,
    ACTION_FILTER_SCROLL_TO_ELEMENT,
    ACTION_FILTER_TEST_GROUP,
    ACTION_FILTER_TIME_ON_PAGE,
    ACTION_FILTER_URL,
    ACTION_FILTER_VISIT_START_TIME,
    ACTION_FILTER_VISITS,
    ACTION_FILTER_WINDOW_HEIGHT,
    ACTION_FILTER_WINDOW_WIDTH,
    IAutoActionFilters
} from "~/chat/ts/data/AutoActions";
import Random from "~/ts/library/Random";
import {WidgetStore} from "~/chat/ts/store/Widget";
import Url from "~/ts/library/Url";
import {ChatDeviceChangeEvent, ConfigStore} from "~/chat/ts/store/Config";
import Punycode from "~/ts/library/Punycode";
import Events from "~/ts/library/Events";
import {Intervals} from "~/ts/library/delay/Intervals";
import WindowHelper from "~/ts/library/WindowHelper";
import Dictionary from "~/ts/library/Dictionary";
import {OperatorsStore} from "~/chat/ts/store/Operators";
//import SearchKeyword from "~/chat/ts/service/SearchKeyword";
import InlineContentWatcher from "~/chat/ts/service/InlineContentWatcher";
import ApiCallback, {API_CALLBACK_CLOSE_SUPPORT} from "~/chat/ts/service/api/methods/ApiCallback";
import {UTM_KEYS} from "~/chat/ts/data/IClient";
import {ClientStore} from "~/chat/ts/store/Client";
import {OPERATOR_STATUS_ONLINE} from "~/chat/ts/data/OperatorStatus";
import {IOperatorGroup} from "~/chat/ts/data/IOperator";
import {computed, watch} from "vue";
import {ChatEventManagerInstance} from "~/chat/ts/ChatEventManager";
import isElementInViewport from "~/ts/library/dom/isElementInViewport";


export default class InitializeFilters {
    public static make() {
        let localFilters = Filters.localFilters;
        for (let group of OperatorsStore.groupList.value) {
            localFilters[this.getOperatorsOfGroupOnlineAndVisibleKey(group)] = true;
            localFilters[this.getOperatorsOfGroupOnlineKey(group)] = true;
        }

        //Filters.set(ACTION_FILTER_SEARCH_KEYWORD, SearchKeyword.get());


        if (Filters.get(ACTION_FILTER_TEST_GROUP) == null) {
            Filters.set(ACTION_FILTER_TEST_GROUP, Random.randomArrayElement(["0", "1"]));
        }
        if (Filters.get(ACTION_FILTER_IS_TARGET_CLIENT) == null) {
            Filters.set(ACTION_FILTER_IS_TARGET_CLIENT, 0);
        }

        ChatEventManagerInstance.addEventListener(ChatDeviceChangeEvent, () => this.setOnDeviceChange());
        this.setOnDeviceChange();
        WidgetStore.setWidgetStateFilter();

        let url = new Url(window.location.href);
        let queryString = url.parseQueryString();
        if (queryString.utm_source && !queryString.utm_medium) {
            if (queryString.gclid) {
                queryString.utm_source = "google";
                queryString.utm_medium = "cpc";
            }
            if (queryString.fbclid) {
                queryString.utm_source = "facebook";
            }
            if (queryString.yclid || queryString.ymclid) {
                queryString.utm_source = "yandex";
                queryString.utm_medium = "cpc";
            }
        }

        let utm: Dictionary<string> = {};
        for (let key of UTM_KEYS) {
            let value = queryString[key];
            if (value) {
                Filters.set(key, value);
            }
            utm[key] = Filters.get(key);
        }
        ClientStore.SET_UTM(utm);

        Filters.set(ACTION_FILTER_REFERER, ConfigStore.referrer.value);
        Filters.incr(ACTION_FILTER_PAGE_VISITED);
        Filters.incr(ACTION_FILTER_PAGES_AT_VISIT);

        this.initializeUrlFilter();
        this.initializeOperatorFilters();
        this.initEvents();

        ApiCallback.setCallback(API_CALLBACK_CLOSE_SUPPORT, () => {
            Filters.set(ACTION_FILTER_CLOSE_WIDGET);
        });
    }

    public static resetOnNewVisit() {
        Filters.set(ACTION_FILTER_VISIT_START_TIME);
        Filters.set(ACTION_FILTER_AUTO_MESSAGE_TO_CLIENT_AT_VISIT, 0);
        Filters.set(ACTION_FILTER_MESSAGES_COUNT_AT_VISIT, 0);
        Filters.set(ACTION_FILTER_PAGES_AT_VISIT, 0);
        Filters.set(ACTION_FILTER_CLIENT_MESSAGES_COUNT_AT_VISIT, 0);
        Filters.set(ACTION_FILTER_OPERATOR_MESSAGES_COUNT_AT_VISIT, 0);
        Filters.incr(ACTION_FILTER_VISITS);
    }

    private static setOnDeviceChange() {
        Filters.set(ACTION_FILTER_DEVICE, ConfigStore.device.value);
    }


    private static getOperatorsOfGroupOnlineAndVisibleKey(group: IOperatorGroup): string {
        return `${ACTION_FILTER_COL_OPERATORS_ONLINE_AND_VISIBLE}_${group.id}`;
    }

    private static getOperatorsOfGroupOnlineKey(group: IOperatorGroup): string {
        return `${ACTION_FILTER_COL_OPERATORS_ONLINE}_${group.id}`;
    }

    private static setOperatorsOnlineFilter() {
        if (OperatorsStore.isStatusInitialized.value) {
            let ids = OperatorsStore.visibleOperators.value.map(operator => operator.id);
            Filters.set(ACTION_FILTER_COL_OPERATORS_ONLINE_AND_VISIBLE, ids.length);
            ids.push("");
            Filters.set(ACTION_FILTER_OPERATOR_ONLINE_AND_VISIBLE, ids);

            ids = OperatorsStore.operatorsList.value
                .filter(operator => operator.status === OPERATOR_STATUS_ONLINE)
                .map(operator => operator.id);
            Filters.set(ACTION_FILTER_COL_OPERATORS_ONLINE, ids.length);

            ids.push("");
            Filters.set(ACTION_FILTER_OPERATOR_ONLINE, ids);

            for (let group of OperatorsStore.groupList.value) {
                let visible = OperatorsStore.getVisibleOperatorsOfGroup(group).length;
                Filters.set(this.getOperatorsOfGroupOnlineAndVisibleKey(group), visible);
                let online = OperatorsStore.getOnlineOperatorsOfGroup(group).length;
                Filters.set(this.getOperatorsOfGroupOnlineKey(group), online);
            }
        }
    }

    private static initializeOperatorFilters() {
        watch(computed(() => {
            if (OperatorsStore.isStatusInitialized.value) {
                return [OperatorsStore.visibleOperators.value.length, OperatorsStore.onlineOperators.value.length];
            } else {
                return false;
            }
        }), () => {
            this.setOperatorsOnlineFilter();
        });

        this.setOperatorsOnlineFilter();
    }

    private static initializeUrlFilter() {
        this.setCurrentUrlFilter();

        Events.addEventListener("hashchange", window, () => {
            this.setCurrentUrlFilter();
        });
        let url = window.location.href;
        Intervals.set(() => {
            let newUrl = window.location.href;
            if (newUrl != url) {
                url = newUrl;
                this.setCurrentUrlFilter();
            }
        }, 1000);
    }

    private static setCurrentUrlFilter() {
        let l, i;
        let url = [window.location.href];
        let currentValue = Filters.get("url");
        if (!currentValue || currentValue[0] !== url[0]) {

            let location = window.location;

            var host = location.host.split(":")[0];
            url.push(url[0].split(host).join(Punycode.toUnicode(host)));

            if (!location.search) {
                l = url.length;
                for (i = 0; i < l; i++) {
                    let u;
                    if (url[i].substr(-1, 1) === "/") {
                        u = url[i].substr(0, url[i].length - 1);
                    } else {
                        u = url[i] + "/";
                    }
                    if (url.indexOf(u) === -1) {
                        url.push(u);
                    }
                }
            }

            var pathname = [location.pathname];
            if (pathname[0].substr(-1, 1) === "/") {
                pathname.push(pathname[0].substr(0, pathname[0].length - 1));
            }

            l = pathname.length;

            for (i = 0; i < l; i++) {
                if (pathname[i].indexOf('/') === 0) {
                    pathname.push(pathname[i].substr(1));
                }
            }

            l = pathname.length;
            for (i = 0; i < l; i++) {
                if (pathname[i].substr(-1, 1) !== "/") {
                    pathname.push(pathname[i] + "/");
                }
            }
            for (i = 0; i < pathname.length; i++) {
                if (location.search) {
                    pathname[i] += location.search;
                }
                if (url.indexOf(pathname[i]) === -1) {
                    url.push(pathname[i]);
                }
            }

            for (i = 0; i < url.length; i++) {
                try {
                    url[i] = encodeURI(Url.safeDecodeURI(url[i]));
                } catch (e) {

                }
            }

            for (i = 0; i < url.length; i++) {
                try {
                    var d = Url.safeDecodeURI(url[i]);
                    if (url.indexOf(d) === -1) {
                        url.push(d);
                    }
                } catch (e) {
                }
            }

            Filters.set(ACTION_FILTER_TIME_ON_PAGE);
            Filters.set(ACTION_FILTER_PREVIOUS_URL, Filters.get("lastLoadURL"));
            Filters.set(ACTION_FILTER_URL, url);
            Filters.set(ACTION_FILTER_CURRENT_PAGE_TITLE, document.title);
            Filters.set("lastLoadURL", url);
        }
    }

    private static setWindowSizeFilters() {
        let data = WindowHelper.getSize();
        Filters.set(ACTION_FILTER_WINDOW_WIDTH, data.width);
        Filters.set(ACTION_FILTER_WINDOW_HEIGHT, data.height);
    }

    private static filtersEventsInited = false;

    private static initEvents() {

        this.setWindowSizeFilters();
        this.initMouseOutFilter();


        if (!this.filtersEventsInited) {
            this.filtersEventsInited = true;
            WindowHelper.onResize(() => {
                this.setWindowSizeFilters();
            });

            let selectors: Dictionary<string[]> = {
                elementOnPage: [],
                scrollToElement: [],
                clickOnElement: []
            };

            let getSelectorsFromFilters = (filters: IAutoActionFilters) => {
                if (filters) {
                    for (let filterName in selectors) {
                        if (selectors.hasOwnProperty(filterName)) {
                            let operations = filters[filterName];
                            for (let operation in operations) {
                                if (operations.hasOwnProperty(operation)) {
                                    for (let selector of operations[operation]) {
                                        if (selectors[filterName].indexOf(selector) == -1) {
                                            selectors[filterName].push(selector);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            };
            let siteParams = ConfigStore.siteParams.value;
            for (let actionBlock of siteParams.params.actionBlocks) {
                getSelectorsFromFilters(actionBlock.filters);
                for (let subFilter of actionBlock.subFilters) {
                    getSelectorsFromFilters(subFilter.filters);
                }
            }

            let jsonActions = JSON.stringify(siteParams.params.actionBlocks);
            let haveScrollFilters = false;
            for (let scrollFilter of ["scrollPX", "scrollPercentage", "scrollToElement"]) {
                if (jsonActions.indexOf(scrollFilter) > -1) {
                    haveScrollFilters = true;
                    break;
                }
            }

            if (document.querySelector) {
                let selectorsOnPage: string[] = [];

                if (selectors.elementOnPage.length) {
                    let elementOnPageCallback = () => {
                        selectorsOnPage.splice(0);

                        for (let selector of selectors.elementOnPage) {
                            try {
                                if (document.querySelector(selector) != null) {
                                    selectorsOnPage.push(selector);
                                }
                            } catch (e) {

                            }
                        }
                        Filters.set(ACTION_FILTER_ELEMENT_ON_PAGE, selectorsOnPage);
                    };
                    Intervals.set(elementOnPageCallback, 1000);
                    elementOnPageCallback();
                }
            }

            if (selectors.clickOnElement.length) {
                Events.addEventListener(ConfigStore.isMobile.value ? "touchend" : "click", document, (e: PointerEvent) => {
                    //$SA.suspend.check(function(){
                    var clickedSelectors = [];
                    let element = e.target as HTMLElement;
                    while (element) {
                        for (let selector of selectors.clickOnElement) {
                            try {
                                if (InlineContentWatcher.isMatches(element, selector)) {
                                    clickedSelectors.push(selector);
                                }
                            } catch (e) {
                            }
                        }
                        element = element.parentElement;
                    }
                    Filters.set(ACTION_FILTER_CLICK_ON_ELEMENT, clickedSelectors, clickedSelectors.length ? 2000 : null, []);

                    //},"setClickActionFilter");
                });
            }

            if (haveScrollFilters) {
                let body = document.body,
                    html = document.documentElement;

                let scrollCallback = () => {
                    let scroll = window.scrollY + WindowHelper.getSize().height;
                    Filters.set(ACTION_FILTER_SCROLL_PX, scroll);
                    let h = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
                    let percentage = parseInt((scroll / (h / 100)) as any);
                    Filters.set(ACTION_FILTER_SCROLL_PERCENTAGE, percentage);

                    let selectorsInViewport = [];
                    if (selectors.scrollToElement.length && document.querySelectorAll) {
                        for (let selector of selectors.scrollToElement) {
                            try {
                                let elements = document.querySelectorAll(selector);
                                for (let j = 0; j < elements.length; j++) {
                                    if (isElementInViewport({
                                        element: elements[j] as HTMLElement,
                                        middleOfScreen: true
                                    })) {
                                        selectorsInViewport.push(selector);
                                        break;
                                    }
                                }
                            } catch (e) {
                            }
                        }
                    }

                    Filters.set(ACTION_FILTER_SCROLL_TO_ELEMENT, selectorsInViewport);
                };

                Events.addEventListener(ConfigStore.isMobile.value ? "touchend" : "scroll", document, function () {
                    scrollCallback();
                });
                scrollCallback();
            }

        }
    }

    private static initMouseOutFilter() {
        Events.addEventListener("mouseout", window, async (e: MouseEvent) => {
            if (!e.relatedTarget) {
                let windowSize = WindowHelper.getSize();
                var res: string;
                if (e.clientX <= 0) {
                    res = "left";
                } else if (e.clientY <= 0) {
                    res = "top";
                } else if (e.clientX > windowSize.width) {
                    res = "right";
                } else if (e.clientY > windowSize.height) {
                    res = "bottom";
                }
                if (res) {
                    Filters.set(ACTION_FILTER_MOUSE_LEAVE_PAGE, res, 2000, null);
                }
            }
        });
    }
}