import ChatApiRequest from "~/chat/ts/service/request/ChatApiRequest";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";
import md5 from "~/ts/library/md5";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";

interface PayloadInterface {
    toAdd?: string[];
    toRemove?: string[];
    isRemoveAllCurrentTags?: boolean;
}

function isChanged(currentTags: string[], changedTags: string[], add: boolean): boolean {
    let result = false;
    if (changedTags) {
        for (let tag of changedTags) {
            if (currentTags.includes(md5(tag)) != add) {
                result = true;
                break;
            }
        }
    }
    return result;
}

export default async function setClientTags(payload: PayloadInterface, callback?: (error?: Error) => void) {
    let currentTags = ChatTabStore.clientTags.sort();
    if (payload.toAdd && payload.toRemove) {
        for (let tag of payload.toRemove) {
            toggleArrayElement(payload.toAdd, tag, false);
        }
    }
    let changed = isChanged(currentTags, payload.toAdd, true) || isChanged(currentTags, payload.toRemove, false);
    if (!changed) {
        if (payload.isRemoveAllCurrentTags) {
            if (payload.toAdd) {
                let newTags = payload.toAdd.map(tag => md5(tag)).sort();
                changed = JSON.stringify(newTags) !== JSON.stringify(currentTags);
            } else {
                changed = currentTags.length > 0;
            }
        }
    }

    let error = null;

    if (changed) {
        let result = await ChatApiRequest.make({
            method: "setClientTags",
            p: payload
        });
        if (!result.isSuccess()) {
            error = new Error(result.getDescr());
        }
    }
    if (callback) {
        callback(error);
    }
}