import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {computed} from 'vue';
import Htmlify from "~/chat/vue/misc/ChatHtmlify.vue";
import {WidgetStore} from "~/chat/ts/store/Widget";


import {defineAsyncComponent} from "vue";

const __default__ = {
    name: "ChatRootModalMessage",
    components: {
        ChatModalMessage: defineAsyncComponent(() => import(/* webpackChunkName: "widget" */ '~/chat/vue/widget/ChatModalMessage.vue')),
    }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    forBody: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;

let modalMessage = computed(() => {
    let result = WidgetStore.modalMessage.value;
    return result && result.appendToBody == props.forBody ? result : null;
});

function visibleUpdated(value: boolean) {
    if (!value) {
        WidgetStore.hideModalMessage();
    }
}


return (_ctx: any,_cache: any) => {
  const _component_chat_modal_message = _resolveComponent("chat-modal-message")!

  return (_unref(modalMessage))
    ? (_openBlock(), _createBlock(_component_chat_modal_message, {
        key: 0,
        "append-to-body": _ctx.forBody,
        title: _unref(modalMessage).title,
        visible: "",
        "onUpdate:visible": visibleUpdated
      }, {
        default: _withCtx(() => [
          _createVNode(Htmlify, {
            escape: _unref(modalMessage).escape,
            value: _unref(modalMessage).text,
            bbcode: _unref(modalMessage).bbcode,
            nl2br: _unref(modalMessage).nl2br
          }, null, 8, ["escape", "value", "bbcode", "nl2br"])
        ]),
        _: 1
      }, 8, ["append-to-body", "title"]))
    : _createCommentVNode("", true)
}
}

})