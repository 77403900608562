import Dictionary from "~/ts/library/Dictionary";

export default interface IMessage {
    id?: number;
    key?: string;
    text?: string;
    from?: string;
    to?: string;
    operatorDescr?: string;
    dt?: string;
    timestamp?: number;
    messageType?: MESSAGE_TYPE;
    visitId?: string;
    hideNotification?: boolean;
    hideBadge?: boolean;
    status?: "readed" | "delivered";
    replyToMessage?: IMessage;
    knowledgeBaseArticles?: { descr: string, url?: string }[];
    cascadeMessageId?: number;
    isBotMessage?: boolean;
    isHideOperator?: boolean;

    other?: {
        attachments?: IMessageAttachment[];
        tab?: {
            id: string;
            title: string;
            type: "html" | "form" | "payment";
            showType: "button" | "show";
        };
        buttons?: IMessageButton[];
        blockTextInput?: boolean;
        temporaryId?: number;
        hiddenText?: boolean;
        clientSideId?: string;
        offline?: boolean;
        sticker?: {
            url: string,
            id?: string
        };
        ttlSeconds?: number;
    };
    prepareToSend?: boolean;
}

export const MESSAGE_TAB_SHOW_TYPE_SHOW = "show";

export const MESSAGE_TYPE_AUTO_MESSAGE = "autoMessage";
export const MESSAGE_TYPE_TAB = "tab";
export const MESSAGE_TYPE_SUPPORT_API = "supportAPI";

export type MESSAGE_TYPE =
    null
    | typeof MESSAGE_TYPE_TAB
    | typeof MESSAGE_TYPE_AUTO_MESSAGE
    | "comment"
    | typeof MESSAGE_TYPE_SUPPORT_API
    | "buttonClick";

export const MESSAGE_BUTTON_ACTION_SEND_MESSAGE_TO_OPERATOR = "sendMessageToOperator";
export const MESSAGE_BUTTON_ACTION_OPEN_WIDGET_TAB = "openWidgetTab";
export const MESSAGE_BUTTON_ACTION_OPEN_URL = "openUrl";
export const MESSAGE_BUTTON_ACTION_SEND_TO_ANALYTICS = "sendToAnalytics";
export const MESSAGE_BUTTON_ACTION_JAVASCRIPT = "javascript";
export const MESSAGE_BUTTON_ACTION_TARGET = "setTargetClient";
export const MESSAGE_BUTTON_ACTION_CLOSE_WIDGET = "closeWidget";
export const MESSAGE_BUTTON_ACTION_START_CHAT_BOT = "startChatBot";

export type MESSAGE_BUTTON_ACTION =
    typeof MESSAGE_BUTTON_ACTION_SEND_MESSAGE_TO_OPERATOR
    | typeof MESSAGE_BUTTON_ACTION_OPEN_WIDGET_TAB
    | typeof MESSAGE_BUTTON_ACTION_OPEN_URL
    | typeof MESSAGE_BUTTON_ACTION_SEND_TO_ANALYTICS
    | typeof MESSAGE_BUTTON_ACTION_JAVASCRIPT
    | typeof MESSAGE_BUTTON_ACTION_TARGET
    | typeof MESSAGE_BUTTON_ACTION_CLOSE_WIDGET
    | typeof MESSAGE_BUTTON_ACTION_START_CHAT_BOT;

//export const MESSAGE_TYPE_FORM = "bot";

export interface IMessageButton {
    actions: IMessageButtonAction[],
    text: string,
    key?: number
}

export interface IMessageAttachment {
    fileName: string;
    url: string;
    size: number;
    type: string | "audio" | "video" | "image";
    imageSize?: {
        width: number,
        height: number
    }
}

export interface IMessageButtonAction {
    type: MESSAGE_BUTTON_ACTION,
    params: Dictionary<any>
}

export interface MessageButtonActionSendMessageToOperatorParamsInterface {
    messageRecipient: string
}

export interface MessageButtonActionOpenWidgetTabParamsInterface {
    tabId: string;
    reviewTabType: "list" | "form"
}

export interface MessageButtonActionOpenUrlParamsInterface {
    url: string,
    newWindow: boolean,
    hideButtonsAfterClick: boolean
}

export interface MessageButtonActionJavascriptParamsInterface {
    script: string
}

export interface MessageButtonActionSendToAnalyticsParamsInterface {
    eventId: string
}

export interface MessageButtonActionTargetParamsInterface {
    reason: string
}

export interface MessageButtonActionCloseWidgetParamsInterface {

}

export interface MessageButtonActionStartChatBotParamsInterface {
    schemeId: string;
}