import { defineComponent as _defineComponent } from 'vue'

import {computed, watch} from 'vue';
import {ConfigStore} from "~/chat/ts/store/Config";
import {WidgetStore} from "~/chat/ts/store/Widget";
import {TriggerStore} from "~/chat/ts/store/Trigger";
import Events from "~/ts/library/Events";
import Delay from "~/ts/library/Delay";
import {addDisposableEvent} from "~/ts/vuePlugins/useDisposableEvent";


const __default__ = {
    name: "MobileBackHandler"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const PUSH_STATE_WIDGET = "onlineChatWidget";

let isMobileBackButtonNeedToBeActive = computed(() => {
    return ConfigStore.isMobile.value && (WidgetStore.isOpened.value || TriggerStore.isTriggerMenuOpened.value);
});

watch(isMobileBackButtonNeedToBeActive, async () => {
    let history = window.history;
    if (history) {
        let value = isMobileBackButtonNeedToBeActive.value;
        let isOurStateHere = isOurStateInHistory();
        if (!isOurStateHere && value) {
            await Delay.make(100, PUSH_STATE_WIDGET, true);
            //Delay.override("popstate");
            history.pushState({
                [PUSH_STATE_WIDGET]: true
            }, document.title);
        } else if (isOurStateHere && !value) {
            history.back();
        }
    }
});

function isOurStateInHistory() {
    let result = false;
    let history = window.history;
    if (history) {
        let state = history.state;
        result = !!(state && state[PUSH_STATE_WIDGET]);
    }
    return result;
}

addDisposableEvent(
    Events.addEventListener("popstate", window, async () => {
        if (ConfigStore.isMobile.value && !isOurStateInHistory()) {
            try {
                /*
                    Танцы с задержкой нужны для того, чтобы виджет сразу не закрывался в таком случае:
                    <a class="dashed" href="#" onclick="MeTalk('openReviewsTab','form')"><span class="dashed">Оставить отзыв</span></a>
                    Сначала срабатывает пушстейт, потом сразу попстейт о том, что мы ушли с #, из-за этого виджет закрывался.
                 */
                await Delay.make(100, PUSH_STATE_WIDGET, false);
                WidgetStore.close();
                TriggerStore.SET_TRIGGER_MENU_OPENED(false);
            } catch (e) {

            }
        }
    })
);


return (_ctx: any,_cache: any) => {
  return null
}
}

})