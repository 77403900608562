import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex w-100" }
const _hoisted_2 = { class: "filler" }
const _hoisted_3 = { class: "d-flex w-100 mb-2" }
const _hoisted_4 = { class: "filler operator-name" }

import NotificationMessageList from "~/chat/vue/notification/NotificationMessageList.vue";
import {IOperator} from "~/chat/ts/data/IOperator";
import OperatorAvatar from "~/chat/vue/widget/tabs/chat/OperatorAvatar.vue";
import CloseIcon from "~/chat/vue/misc/CloseIcon.vue";
import {ConfigStore} from "~/chat/ts/store/Config";
import {DEVICE_MOBILE} from "~/ts/library/Device";
import {TriggerStore} from "~/chat/ts/store/Trigger";
import WindowHelper from "~/ts/library/WindowHelper";
import {useNotification} from "~/chat/vue/notification/useNotification";
import {computed, watch} from "vue";


const __default__ = {
    name: "PopupNotification"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const TRIGGER_HIDE_REASON = "popupNotifyHide";

let {items, close, open} = useNotification();


let operator = computed(() => {
    let result: IOperator;
    for (let i = items.value.length - 1; i >= 0; i--) {
        result = items.value[i].operator;
        if (result) {
            break;
        }
    }
    return result;
});

let avatar = computed(() => {
    let result = null;
    if (operator.value) {
        if (!operator.value.isBot || ConfigStore.siteParams.value.params.bot.isAvatarEnabled) {
            result = operator.value.avatar;
        }
    }
    return result;
});

let isMobile = computed(() => {
    return ConfigStore.device.value == DEVICE_MOBILE;
});


function triggerToggle() {
    if (isTriggerMustBeHidden.value) {
        TriggerStore.hideTrigger(TRIGGER_HIDE_REASON)
    } else {
        TriggerStore.showTrigger(TRIGGER_HIDE_REASON);
    }
}

let isTriggerMustBeHidden = computed(() => {
    return items.value.length > 0 && isMobile.value;
});

watch(isTriggerMustBeHidden, () => triggerToggle());


let style = computed(() => {
    if (ConfigStore.needScaleFix.value) {
        let metaWithDeviceWidth = document.querySelector("meta[name=viewport][content*=device-width]");
        let metaViewport = document.querySelector("meta[name=viewport]")
        let needWidthScaleZoomFix = metaViewport && !metaWithDeviceWidth;
        let scaleZoomFixWidth = needWidthScaleZoomFix ? WindowHelper.scaleZoomFix : 1;
        let style: any = {
            zoom: `${WindowHelper.scaleZoomFix} !important`,
            transform: `translateX(${WindowHelper.scaleLeftFix}) translateY(${WindowHelper.scaleBottomFix}) !important`,
            width: `calc(${100 / scaleZoomFixWidth}vw / ${WindowHelper.getVisibleViewport().bodyZoom} - 2em) !important`
            // width: `${width}px`
        };
        if (WindowHelper.isWindowOrViewportEventRun) {
            style.display = "none !important";
        }
        return style
    }
    return null;
});

triggerToggle();

return (_ctx: any,_cache: any) => {
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createBlock(_Transition, { name: "list" }, {
    default: _withCtx(() => [
      (_unref(items).length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "popup-notification cursor-pointer z-index",
            style: _normalizeStyle(_unref(style)),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(open) && _unref(open)(...args)))
          }, [
            _createElementVNode("div", _hoisted_1, [
              (_unref(avatar))
                ? (_openBlock(), _createBlock(OperatorAvatar, {
                    key: 0,
                    src: _unref(avatar),
                    name: _unref(operator).fio,
                    "without-border": "",
                    class: "mr-4 avatar"
                  }, null, 8, ["src", "name"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    (_unref(operator))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(_unref(operator).fio), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _withDirectives(_createVNode(CloseIcon, {
                    class: _normalizeClass({mobile: _unref(isMobile)})
                  }, null, 8, ["class"]), [
                    [
                      _directive_on_native,
                      _unref(close),
                      "click",
                      { stop: true }
                    ]
                  ])
                ]),
                _createVNode(NotificationMessageList, {
                  class: "filler",
                  messages: _unref(items)
                }, null, 8, ["messages"])
              ])
            ])
          ], 4))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})