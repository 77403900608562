import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ChatLoader from "~/chat/vue/misc/ChatLoader.vue";
import WidgetLoaderWrapper from "~/chat/vue/widget/WidgetLoaderWrapper.vue";

const __default__ = {
    name: "WidgetLoader"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(WidgetLoaderWrapper, { class: "widget-loader" }, {
    default: _withCtx(() => [
      _createVNode(ChatLoader, { class: "loader" })
    ]),
    _: 1
  }))
}
}

})