import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {USE_TRIGGER_EMITS, useTrigger, useTriggerProps} from "~/chat/vue/trigger/useTrigger";
import Dictionary from "~/ts/library/Dictionary";


const __default__ = {
    name: "HiddenTrigger"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useTriggerProps()
},
  emits: ["update:inline-styles", "default-bubble-text", "updated"],
  setup(__props, { emit: __emit }) {

let props = __props;

let emits = __emit;

let {} = useTrigger(props, {
    isBubbleTextAvailable: () => false
});

emits("update:inline-styles", {
    display: "block !important"
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})