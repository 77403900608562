import InlineContentWatcher from "~/chat/ts/service/InlineContentWatcher";
import {App, createApp} from "vue";
import {ConfigStore} from "~/chat/ts/store/Config";
import {prepareChatApp} from "~/chat/ts/initialize/PrepareChatApp";

export default class InlineTabWatcher extends InlineContentWatcher {
    protected async getComponent(container: HTMLElement): Promise<App> {
        let tabId = this.attr(container, "id");
        let tab = ConfigStore.tabsDictionary.value[tabId];
        if (tab) {
            let ContentTab = (await (() => import(/* webpackChunkName: "widget" */ '~/chat/vue/widget/tabs/ContentTabInner.vue'))() as any).default;

            return prepareChatApp(createApp(
                {
                    ...ContentTab
                }, {
                    tab: tab
                }));
        } else {
            throw new Error("Вкладка не найдена");
        }
    }

    protected get selector(): string {
        return ".meTalkForm, .online-chat-inline-tab";
    }

}