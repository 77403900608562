import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["viewBox"]
const _hoisted_2 = ["d"]

import {computed} from 'vue';
import {ConfigStore} from "~/chat/ts/store/Config";
import {SVG_ICONS} from "~/chat/vue/misc/Icons";
import {IconStyle, SvgIcon as SvgIconType} from "~/chat/ts/data/ISiteParams";
import Dictionary from "~/ts/library/Dictionary";


const __default__ = {
    name: "SvgIcon"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    iconName: {},
    iconStyle: { default: "regular" }
  },
  setup(__props: any) {

let props = __props;

let svg = computed<SvgIconType>(() => {
    let config = ConfigStore.siteParams.value;
    if (config) {
        let iconName = props.iconName;
        let iconStyle = props.iconStyle;
        if (iconName.indexOf("icon-") == 0) {
            iconName = iconName.substr(5);
        }
        let icon: Dictionary<SvgIconType> = config.icons[iconName];
        if (icon && !icon[iconStyle]) {
            for (let key in icon) {
                iconStyle = key as IconStyle;
                break;
            }
        }
        if (!icon || !(icon as any)[iconStyle]) {
            icon = SVG_ICONS[iconName];
        }
        if (icon) {
            return icon[iconStyle];
        }
    }
    return null;
});

let style = computed(() => {
    if (props.iconName == "paper-plane") {
        return {
            paddingRight: ".05em !important"
        }
    }
    return {};
});

return (_ctx: any,_cache: any) => {
  return (_ctx.iconStyle == 'img')
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "svg-icon img",
        style: _normalizeStyle({
            ..._unref(style),
            backgroundImage: `url(${_ctx.iconName}) !important`
        })
      }, null, 4))
    : (_unref(svg))
      ? (_openBlock(), _createElementBlock("svg", {
          key: 1,
          class: "svg-icon",
          fill: "currentColor",
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: `0 0 ${_unref(svg).width} ${_unref(svg).height}`,
          style: _normalizeStyle(_unref(style))
        }, [
          _createElementVNode("path", {
            d: _unref(svg).content
          }, null, 8, _hoisted_2)
        ], 12, _hoisted_1))
      : _createCommentVNode("", true)
}
}

})