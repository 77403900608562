import {__} from "~/ts/library/Translate";
import {App} from "vue";

const ChatTranslatePlugin = {
    install: function (app: App) {
        app.config.globalProperties.__ = __;
    }
};

export default ChatTranslatePlugin;
