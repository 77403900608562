import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "trigger-image" }
const _hoisted_2 = ["src"]

import Dictionary from "~/ts/library/Dictionary";
import TriggerDropdownContainer from "~/chat/vue/trigger/TriggerDropdownContainer.vue";
import {USE_TRIGGER_EMITS, useTrigger, useTriggerProps} from "~/chat/vue/trigger/useTrigger";
import {computed} from "vue";


const __default__ = {
    name: "ImageTrigger"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useTriggerProps()
},
  emits: ["default-bubble-text", "updated"],
  setup(__props, { emit: __emit }) {

let props = __props;

let emits = __emit;

let {isOnline, defaultBubbleText} = useTrigger(props, {
    isBubbleTextAvailable: () => !!props.config.roundTextEnabled
})

let style = computed(() => {
    let result: Dictionary<string> = {
        width: `${image.value.width}px !important`,
        height: `${image.value.height}px !important`
    };

    return result;
});

let image = computed<{ src: string, height: number, width: number }>(() => {
    let config = props.config;
    return isOnline.value ? {
        src: config.onlineImage,
        width: config.onlineImageWidth,
        height: config.onlineImageHeight
    } : {
        src: config.offlineImage,
        width: config.offlineImageWidth,
        height: config.offlineImageHeight
    };
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(TriggerDropdownContainer, {
      "default-bubble-text": _unref(defaultBubbleText),
      "trigger-width": _unref(image).width
    }, null, 8, ["default-bubble-text", "trigger-width"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        style: _normalizeStyle(_unref(style)),
        src: _unref(image).src
      }, null, 12, _hoisted_2)
    ])
  ]))
}
}

})