import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment, createElementBlock as _createElementBlock, isRef as _isRef, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]

import {Component, computed, provide, ref, watch} from 'vue';
import {ConfigStore} from "~/chat/ts/store/Config";
import ChatTrigger from "~/chat/vue/trigger/ChatTrigger.vue";
import WidgetContainer from "~/chat/vue/widget/WidgetContainer.vue";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import Dictionary from "~/ts/library/Dictionary";
import {WidgetStore} from "~/chat/ts/store/Widget";
import PopupNotification from "~/chat/vue/notification/PopupNotification.vue";
import {TriggerStore} from "~/chat/ts/store/Trigger";
import MobileFix from "~/chat/vue/MobileFix.vue";
import {CobrowseOuterStore} from "~/chat/ts/store/CobrowseOuter";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import ApiCallback, {
    API_CALLBACK_FULLY_LOADED,
    API_CALLBACK_UNREADED_MESSAGES_COUNT
} from "~/chat/ts/service/api/methods/ApiCallback";
import MobileBackHandler from "~/chat/vue/MobileBackHandler.vue";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";
import ChatRootModalMessage from "~/chat/vue/widget/ChatRootModalMessage.vue";
import {IContentEdge, TRIGGER_MENU_TYPE_MOBILE} from "~/chat/ts/data/ISiteParams";
import TriggerMenu from "~/chat/vue/trigger/menu/TriggerMenu.vue";
import BodyClassHandler from "~/chat/vue/BodyClassHandler.vue";
import {ClientStore} from "~/chat/ts/store/Client";
import ChatStyle from "~/chat/vue/misc/ChatStyle.vue";
import {LightboxInjection} from "~/core-ui/ts/LightboxInjection";
import ChatLightbox from "~/chat/vue/misc/ChatLightbox.vue";
import Device from "~/ts/library/Device";
import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";


const __default__ = {
    name: "ChatRootComponent"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let isMobile = computed(() => ConfigStore.isMobile.value);
let lightboxSrc = ref<string>();
provide(LightboxInjection, src => lightboxSrc.value = src);

let isNeedMobileFix = computed(() => {
    let isMobileMenuOpened = TriggerStore.isTriggerMenuOpened.value && TriggerStore.deviceMenu.value && TriggerStore.deviceMenu.value.displayType == TRIGGER_MENU_TYPE_MOBILE;
    //TODO: с этим inline - скользкая тема. Если-таки будем делать одновременную вставку в inline и обычную версию, то надо все переосмысливать
    return isMobile.value && (WidgetStore.isOpened.value || isMobileMenuOpened) && !WidgetStore.inlineMode.value;
});

let isWidgetMustBeHiddenBecauseOfOffline = ChatTabStore.isWidgetMustBeHiddenBecauseOfOffline;

const mobileTriggerMenuTypeId = TRIGGER_MENU_TYPE_MOBILE;

let isReadyToMount = computed(() => {
    let result = false;
    if (!ClientStore.isBanned.value) {
        if (!ConfigStore.isWidgetDependsOnOperatorState.value || OperatorsStore.isStatusInitialized.value) {
            result = true;
        }
    }
    return result;
});

let siteId = ConfigStore.siteId;

let isCobrowseEnabled = CobrowseOuterStore.isCobrowseWorkingNow;

let isInlineMode = computed(() => WidgetStore.inlineMode.value);

let cobrowseWrapperComponent = computed<Component>(() => {
    return useRawAsyncComponent(() => import(/* webpackChunkName: "cobrowse" */ '~/chat/vue/cobrowse/CobrowseWrapper.vue'))
});

let containerId = computed(() => WidgetStore.widgetRootContainerId.value);

let className = computed(() => {

    let result: string[] = ["notranslate", "online-chat-components-wrapper", "device-type-" + ConfigStore.device.value];

    if (ConfigStore.isIe.value) {
        result.push("ie");
    }

    if (!isReadyToMount.value) {
        result.push("support-widget-not-ready");
    }

    if (ConfigStore.hasContentBorderRadius.value) {
        result.push("has-content-border-radius");
    }
    return result;
});

let designParams = computed(() => {
    return siteParams.value?.params.designParams;
});

let siteParams = computed(() => {
    return ConfigStore.siteParams.value;
});

let fontImportUrl = computed<string>(() => {
    if (designParams.value) {
        let font = designParams.value.fontFamily;
        if (font == "'Montserrat', sans-serif") {
            return "https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap&subset=cyrillic";
        } else if (font == "'Roboto', sans-serif") {
            return "https://fonts.googleapis.com/css?family=Roboto:400,600,700&display=swap&subset=cyrillic";
        } else if (font == "'Open Sans', sans-serif") {
            return "https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap&subset=cyrillic";
        }
    }
    return null;
});

let cssFontConfig = computed(() => {
    let url = fontImportUrl.value;
    return url ? `@import url('${url}');` : null;
});


let cssConfig = computed(() => {
    let css: Dictionary<any> = {};

    let design = designParams.value;
    if (design) {
        let trigger = TriggerStore.triggerParams.value;

        if (trigger) {
            let scrollRailMargin: string = null;//design.contentBorderRadius ? `${design.borderRadius}px` : null;
            if (design.content.border.top.round) {
                let value = design.borderRadius - 5;
                if (value > 0) {
                    scrollRailMargin = `${value}px`;
                }
            }


            let widgetHeaderPaddingTop = `${design.widget.header.paddingTopBottom}em`;


            let inputs = ['input:not([type=radio]):not([type=checkbox]):not([type=file]):not([type=submit]):not([type=button])', 'textarea', 'select', '.like-input'];
            let inputSelectors = inputs.join(",");
            let buttons = ['button', 'input[type=submit]', '.btn'];
            let buttonSelectors = buttons.join(",");

            let popup = design.trigger.popup;

            let vkNotify = design.vkLikeNotification.desktop;

            css = {
                '': {
                    'font-family': design.fontFamily,
                    'font-size': design.fontSize,
                },
                '.trigger-background': {
                    background: ConfigStore.triggerBackground.value,
                    color: design.triggerColor
                },
                '.badge-background': {
                    background: design.badgeBackground,
                    color: design.badgeColor
                },
                'a': {
                    color: design.linkColor
                },
                'a:visited': {
                    color: design.linkColor
                },
                '.link-color': {
                    color: design.linkColor
                },
                'a:hover': {
                    color: design.linkColor//design.linkHoverColor
                },
                '.default-font-size': {
                    'font-size': design.fontSize
                },
                '.trigger-font-size': {
                    'font-size': `${trigger.fontSize}px`
                },
                '.trigger-font-family': {
                    'font-family': `${trigger.font}`
                },
                '.content-background': {
                    background: '#ffffff'
                },
                '.top-z-index': {
                    'z-index': design.zIndex
                },
                '.typo': {
                    'letter-spacing': `${design.letterSpacing}em`
                },
                '.client-message-background': {
                    background: design.clientMessageBackground,
                    color: design.clientMessageColor
                },
                '.client-message-background a, .client-message-background a:hover': {
                    color: design.clientMessageColor
                },
                '.operator-message-background': {
                    background: design.operatorMessageBackground,
                    color: design.operatorMessageColor
                },
                '.bot-message-background': {
                    background: design.botMessageBackground ?? design.operatorMessageBackground,
                    color: design.botMessageColor ?? design.operatorMessageColor
                },
                '.operator-message-background a, .operator-message-background a:hover': {
                    color: design.operatorMessageColor
                },
                '.online-chat-widget-container': {
                    border: design.border.enabled ? `${design.border.width}px solid ${design.border.color}` : "0px",
                    background: design.border.enabled ? design.border.color : "#fff",
                    'box-shadow': design.boxShadow.enabled ? `0 0 ${design.boxShadow.width}px ${design.boxShadow.color}` : "none"
                },
                '.online-chat-widget-container-border-radius': {
                    'border-radius': `${design.borderRadius}px`
                },
                '.has-content-border-radius .content-border-radius-top': {
                    'border-top-left-radius': `${design.borderRadius}px`,
                    'border-top-right-radius': `${design.borderRadius}px`
                },
                '.has-content-border-radius .content-border-radius-bottom': {
                    'border-bottom-left-radius': `${ConfigStore.isMobile.value ? 0 : design.borderRadius}px`,
                    'border-bottom-right-radius': `${ConfigStore.isMobile.value ? 0 : design.borderRadius}px`
                },
                '.force-content-border-radius-bottom': {
                    'border-bottom-left-radius': `${design.borderRadius}px`,
                    'border-bottom-right-radius': `${design.borderRadius}px`
                },
                '.online-chat-widget-container.drag-active': {
                    'box-shadow': design.boxShadow.enabled ? `0 20px ${design.boxShadow.width * 2}px ${design.boxShadow.color}` : "none"
                },
                '.widget-header-background': {
                    background: ConfigStore.headerBackground.value,
                },
                '.widget-header-color': {
                    color: design.headerColor
                },
                '.widget-header': {
                    'padding-top': widgetHeaderPaddingTop,
                    'padding-bottom': widgetHeaderPaddingTop
                },
                '.operator-avatar.with-border': {
                    border: `1px solid ${design.headerColor}`
                },
                '.message-border-radius': {
                    'border-radius': design.messageBorderRadius + "px"
                },
                '.like-link-light-border': {
                    border: `1px solid ${design.linkColor}2e`,
                    color: design.linkColor
                },
                '.like-link-border': {
                    border: `1px solid ${design.linkColor}`
                },
                '.link-color-background': {
                    background: design.linkColor
                },
                [`.chat-selectable-item.selected${ConfigStore.isMobile.value ? '' : ', .chat-selectable-item:hover'}`]: {
                    'box-shadow': `0 .5em .8em ${design.linkColor}2e`
                },
                '.btn.primary.inverse, button.primary.inverse, input[type=submit].primary.inverse, .btn.primary.inverse:focus, button.primary.inverse:focus, input[type=submit].primary.inverse:focus, .btn.primary.inverse:active, button.primary.inverse:active, input[type=submit].primary.inverse:active': {
                    color: design.buttonSaveBg,
                    background: design.buttonSaveColor
                },
                '.btn.primary, button.primary, input[type=submit].primary, .btn.primary:focus, button.primary:focus, input[type=submit].primary:focus, .btn.primary:active, button.primary:active, input[type=submit].primary:active, .btn.primary.inverse:hover:not(.mobile), button.primary.inverse:hover:not(.mobile), input[type=submit].primary.inverse:hover:not(.mobile)': {
                    background: design.buttonSaveBg,
                    color: design.buttonSaveColor,
                    'border-color': design.buttonSaveBg
                },
                '.content-border-radius-top > .ps__rail-y': {
                    margin: scrollRailMargin ? `${scrollRailMargin} 0` : null
                },
                '.chat-history-wrapper > .ps__rail-y': {
                    'margin-top': scrollRailMargin ? scrollRailMargin : null,
                    'margin-bottom': design.dialog.border.bottom.round && scrollRailMargin ? scrollRailMargin : null
                },
                '.widget-tab-content': widgetTabContentStyle.value,

                '.placeholder-color, .placeholder-color-when-blurred:not(:focus), input::placeholder, textarea::placeholder': {
                    color: design.input.placeholderColor
                },
                '.chat-textarea-wrapper.d-flex.vertical': chatTextareaDesign.value,
                '.chat-textarea-wrapper textarea::placeholder': {
                    color: design.dialog.textarea.controlsColor
                },
                '.chat-textarea-wrapper textarea': {
                    'border-radius': chatTextareaDesign.value["border-radius"]
                },
                [`${inputSelectors}, ${buttonSelectors}`]: {
                    border: `1px solid ${design.input.borderColor}`,
                    'background-color': `${design.input.backgroundColor}`
                },

                '.trigger-popup .inner, .trigger-popup .inner:before, .trigger-popup .inner:after': {
                    'border-color': popup.borderColor,
                },
                '.trigger-popup .inner': {
                    'background': popup.background,
                    'color': popup.background
                },
                '.trigger-popup .inner .slot': {
                    'color': popup.textColor
                },
                '.trigger-popup .inner .close': {
                    'background': popup.close.background,
                    'color': popup.close.color
                },
                '.trigger-popup .trigger-menu li:hover': {
                    'background': popup.hoverMenuItem.background,
                    'color': popup.hoverMenuItem.color
                },
                '.trigger-popup .trigger-menu li > a': {
                    'border-bottom': `1px solid ${popup.hoverMenuItem.background}`
                },
                '.popup-notification': {
                    'background': design.vkLikeNotification.background,
                    'color': design.vkLikeNotification.textColor
                },
                '.device-type-desktop .popup-notification': {
                    left: "auto",
                    right: "auto",
                    bottom: "auto",
                    top: "auto",
                    [vkNotify.side1.side]: `${vkNotify.side1.offset}px`,
                    [vkNotify.side2.side]: `${vkNotify.side2.offset}px`
                }
            };

            if (Device.mobile()) {
                let hoverInputSelectors = inputs
                    .map(input => `${input}:not(.error)`)
                    .map(input => `${input}:hover, ${input}:focus, .input-container:hover ${input}, .input-container:focus-within ${input}`)
                    .join(",");
                let hoverButtonSelectors = buttons.map(button => `${button}:hover:not(.primary):not(.inverse)`).join(",");
                css[`${hoverInputSelectors}, ${hoverButtonSelectors}`] = {
                    'border-color': design.input.borderColorHover,
                    'background-color': `${design.input.backgroundColor}`
                    //'background-color': `${design.input.backgroundColorHover}`
                };
            }

        }
    }
    return css;
});

let widgetTabContentBorderRadius = computed(() => {
    let design = designParams.value;
    let content = design.content;
    let outerBorderRadius = design.borderRadius;
    let result = 0;
    if (outerBorderRadius > 0) {
        let marginInPx = parseInt(design.fontSize) * content.margin;
        result = outerBorderRadius - marginInPx / 2;
    }
    return result > 0 ? result : 0;
});

let widgetTabContentStyle = computed(() => {
    let design = designParams.value;
    let content = design.content;
    let margin = content.margin;
    let border = content.border;
    return {
        'margin': `0 ${margin}em ${margin}em`,
        'overflow': "hidden",
        'border-top': makeContentEdgeStyle(border.top),
        'border-bottom': makeContentEdgeStyle(border.bottom),
        'border-left': makeContentEdgeStyle(border.left),
        'border-right': makeContentEdgeStyle(border.right)
    };
});

function makeContentEdgeStyle(value: IContentEdge): string {
    return value.width ? `${value.width}px solid ${value.color}` : "none";
}

let chatTextareaDesign = computed(() => {
    let textareaDesign = designParams.value.dialog.textarea;
    let border = textareaDesign.border;
    let margin = textareaDesign.margin;
    let contentBorderRadius = widgetTabContentBorderRadius.value;
    let marginTop = `${margin.top}em`;
    let paddingTop = "0";
    if (margin.top == 0 && contentBorderRadius > 0) {
        marginTop = `-${contentBorderRadius}px`;
        paddingTop = `${contentBorderRadius}px`
    }
    return {
        'height': 'auto',
        'background': `${textareaDesign.background}`,
        'color': `${textareaDesign.textColor}`,
        'border': `${border.width}px solid ${border.color}`,
        'margin': `${marginTop} ${margin.right}em ${margin.bottom}em ${margin.left}em`,
        'border-radius': `${border.radius}px`,
        'padding-top': `${paddingTop}`
    };
});

let usePopupNotification = computed(() => {
    return ConfigStore.popupNotifyOnNewMessage.value && !WidgetStore.isOpened.value;
});


function emitUnreadedMessagesCount() {
    ApiCallback.emit(API_CALLBACK_UNREADED_MESSAGES_COUNT, unreadedMessagesCount.value);
}

let unreadedMessagesCount = computed(() => MessageStorage.getUnreadedMessageCount());

watch(unreadedMessagesCount, () => emitUnreadedMessagesCount());

ApiCallback.setCallback(
    API_CALLBACK_FULLY_LOADED,
    () => emitUnreadedMessagesCount(),
    true
);


return (_ctx: any,_cache: any) => {
  return (_unref(siteParams) != null)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_unref(className)),
        ref: "root",
        id: _unref(containerId)
      }, [
        (_unref(isReadyToMount))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(ChatStyle, {
                id: "online-chat-widget-styles",
                css: _unref(cssConfig)
              }, null, 8, ["css"]),
              (_unref(designParams).css && _unref(designParams).css.length)
                ? (_openBlock(), _createBlock(ChatStyle, {
                    key: 0,
                    "css-string": _unref(designParams).css,
                    id: "online-chat-widget-custom-styles"
                  }, null, 8, ["css-string"]))
                : _createCommentVNode("", true),
              (_unref(cssFontConfig))
                ? (_openBlock(), _createBlock(ChatStyle, {
                    key: 1,
                    id: "online-chat-widget-font",
                    "css-string": _unref(cssFontConfig)
                  }, null, 8, ["css-string"]))
                : _createCommentVNode("", true),
              _createVNode(ChatStyle, {
                id: "online-chat-mobile-body",
                "append-root-selector": false,
                "css-string": `html.mobile-online-chat-opened > body.supportOpened { background: ${_unref(ConfigStore).headerBackground.value} !important;`
              }, null, 8, ["css-string"]),
              (!_unref(isInlineMode))
                ? (_openBlock(), _createBlock(WidgetContainer, { key: 2 }))
                : _createCommentVNode("", true),
              (_unref(isCobrowseEnabled))
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(cobrowseWrapperComponent)), { key: 3 }))
                : _createCommentVNode("", true),
              (!_unref(isWidgetMustBeHiddenBecauseOfOffline))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                    _createVNode(ChatTrigger),
                    (_unref(usePopupNotification))
                      ? (_openBlock(), _createBlock(PopupNotification, { key: 0 }))
                      : _createCommentVNode("", true),
                    _createVNode(TriggerMenu, {
                      "allowed-display-types": [_unref(mobileTriggerMenuTypeId)]
                    }, null, 8, ["allowed-display-types"])
                  ], 64))
                : _createCommentVNode("", true),
              (_unref(isNeedMobileFix))
                ? (_openBlock(), _createBlock(MobileFix, { key: 5 }))
                : _createCommentVNode("", true),
              (_unref(siteParams).params.siteChatOptions.mobileBackHandler)
                ? (_openBlock(), _createBlock(MobileBackHandler, { key: 6 }))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        _createVNode(ChatRootModalMessage, { "for-body": "" }),
        _createVNode(BodyClassHandler),
        (_unref(lightboxSrc))
          ? (_openBlock(), _createBlock(ChatLightbox, {
              key: 1,
              src: _unref(lightboxSrc),
              visible: "",
              "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_isRef(lightboxSrc) //@ts-ignore
 ? lightboxSrc.value = null : lightboxSrc = null))
            }, null, 8, ["src"]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})