import {App} from "vue";
import {vOnNative} from "~/ts/vuePlugins/vOnNative";
import ChatTranslatePlugin from "~/chat/ts/initialize/ChatVueTranslatePlugin";
import VueClickAway from "vue3-click-away";

export function prepareChatApp(app: App): App {
    return app
        .directive('on-native', vOnNative)
        .use(VueClickAway)
        .use(ChatTranslatePlugin);
}