import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "slot" }

import CloseIcon from "~/chat/vue/misc/CloseIcon.vue";
import {computed, ref} from "vue";
import {ConfigStore} from "~/chat/ts/store/Config";
import WindowHelper from "~/ts/library/WindowHelper";
import {TriggerStore} from "~/chat/ts/store/Trigger";
import {TRIGGER_OFFSET_SIDE_PRIMARY, TRIGGER_SIDE_LEFT, TRIGGER_SIDE_RIGHT} from "~/chat/ts/data/ISiteParams";


const __default__ = {
    name: "TriggerPopup"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    closable: { type: Boolean },
    arrow: { type: Boolean, default: true }
  },
  emits: ["click", "close"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let isTouch = ConfigStore.isMobile;

let rootElement = ref<HTMLDivElement>();

let maxWidth = computed(() => {
    let viewport = WindowHelper.getSize().viewport;
    let triggerOffset = TriggerStore.triggerParams.value.offset;
    let primarySide = triggerOffset[TRIGGER_OFFSET_SIDE_PRIMARY].side;
    let isTriggerOnLeftRight = [TRIGGER_SIDE_LEFT, TRIGGER_SIDE_RIGHT].includes(primarySide);
    const paddings = 15 / viewport.scale;
    let maxWidth = viewport.width - paddings;
    if (isTriggerOnLeftRight) {
        let boundingRect = rootElement.value?.getBoundingClientRect();
        if (boundingRect) {
            if (primarySide == TRIGGER_SIDE_RIGHT) {
                let right = viewport.width - boundingRect.right;
                maxWidth -= right;
            } else {
                maxWidth -= boundingRect.left + paddings * 2;
            }
        }
    }

    return `${maxWidth * viewport.scale}px !important`;
})


return (_ctx: any,_cache: any) => {
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            'trigger-popup': true,
        }),
    ref_key: "rootElement",
    ref: rootElement,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('click'))),
    style: _normalizeStyle({
            maxWidth: _unref(maxWidth)
        })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
            inner: true,
            'with-arrow': _ctx.arrow
        })
    }, [
      (_ctx.closable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['close', _unref(isTouch) ? 'touch' : null])
          }, [
            _withDirectives(_createVNode(CloseIcon, null, null, 512), [
              [
                _directive_on_native,
                () => _unref(emits)('close'),
                "click",
                { stop: true }
              ]
            ])
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 2)
  ], 4))
}
}

})