import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "devided" }

import TriggerMenuItem from "~/chat/vue/trigger/menu/TriggerMenuItem.vue";
import SvgIcon from "~/chat/vue/misc/SvgIcon.vue";
import {TriggerStore} from "~/chat/ts/store/Trigger";
import {ConfigStore} from "~/chat/ts/store/Config";
import WindowHelper from "~/ts/library/WindowHelper";
import {useTriggerMenu, useTriggerMenuProps} from "~/chat/vue/trigger/menu/useTriggerMenu";
import {computed, onMounted, onUnmounted} from "vue";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "MobileTriggerMenu"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useTriggerMenuProps()
},
  setup(__props) {

let props = __props;

let {menuItems} = useTriggerMenu(props);

function close() {
    TriggerStore.SET_TRIGGER_MENU_OPENED(false);
}

onMounted(() => {
    TriggerStore.SET_MOBILE_TRIGGER_MENU_DISPLAYED(true);
});

onUnmounted(() => {
    TriggerStore.SET_MOBILE_TRIGGER_MENU_DISPLAYED(false);
});


let ulStyle = computed(() => {
    if (ConfigStore.needScaleFix.value) {
        return {
            zoom: `${WindowHelper.scaleZoomFix} !important`,
            transform: `translateX(${WindowHelper.scaleLeftFix}) translateY(${WindowHelper.scaleBottomFix}) !important`,
            width: `calc(100vw / ${WindowHelper.getVisibleViewport().bodyZoom}) !important`
        };
    }
    return null;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "wrapper mobile-trigger-menu-wrapper",
    onClick: close
  }, [
    _createElementVNode("ul", {
      style: _normalizeStyle(_unref(ulStyle))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuItems), (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.id
        }, [
          _createVNode(TriggerMenuItem, { item: item }, {
            default: _withCtx(({ descr }) => [
              _createVNode(SvgIcon, {
                class: "icon",
                "icon-name": item.iconName,
                style: _normalizeStyle({ color: `${item.color1} !important` }),
                "icon-style": item.iconStyle
              }, null, 8, ["icon-name", "style", "icon-style"]),
              _createTextVNode(" " + _toDisplayString(descr), 1)
            ]),
            _: 2
          }, 1032, ["item"])
        ]))
      }), 128)),
      _createElementVNode("li", _hoisted_1, [
        _createVNode(TriggerMenuItem, { onClick: close }, {
          default: _withCtx(() => [
            _createVNode(SvgIcon, {
              class: "icon",
              "icon-name": "reply",
              style: { color: `#cecece !important` }
            }),
            _createTextVNode(" " + _toDisplayString(_unref(__)("CLOSE")), 1)
          ]),
          _: 1
        })
      ])
    ], 4)
  ]))
}
}

})