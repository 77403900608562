import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "descr default-font-size" }

import TriggerMenuItem from "~/chat/vue/trigger/menu/TriggerMenuItem.vue";

import RoundTriggerInner from "~/chat/vue/trigger/RoundTriggerInner.vue";
import {TriggerStore} from "~/chat/ts/store/Trigger";
import RoundTriggerBadge from "~/chat/vue/trigger/RoundTriggerBadge.vue";
import {useTriggerMenu, useTriggerMenuProps} from "~/chat/vue/trigger/menu/useTriggerMenu";
import {computed} from "vue";


const __default__ = {
    name: "RoundTriggerMenu"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useTriggerMenuProps()
},
  setup(__props) {

let props = __props;

let {menuItems} = useTriggerMenu(props);

let triggerParams = TriggerStore.triggerParams;

let ulStyle = computed(() => {
    return {
        fontSize: `${triggerParams.value.roundTriggerSize}px !important`
    };
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", {
    class: "trigger-menu",
    style: _normalizeStyle(_unref(ulStyle))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuItems), (item) => {
      return (_openBlock(), _createElementBlock("li", {
        key: item.id
      }, [
        _createVNode(TriggerMenuItem, { item: item }, {
          default: _withCtx(({ descr, isChatTab }) => [
            _createVNode(RoundTriggerInner, {
              "icon-name": item.iconName,
              "icon-style": item.iconStyle,
              background: item.color1,
              color: item.color2,
              size: _ctx.triggerWidth
            }, {
              default: _withCtx(() => [
                isChatTab
                  ? (_openBlock(), _createBlock(RoundTriggerBadge, { key: 0 }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", null, _toDisplayString(descr), 1)
                ])
              ]),
              _: 2
            }, 1032, ["icon-name", "icon-style", "background", "color", "size"])
          ]),
          _: 2
        }, 1032, ["item"])
      ]))
    }), 128))
  ], 4))
}
}

})