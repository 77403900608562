import { defineComponent as _defineComponent } from 'vue'

import {computed, onBeforeUnmount, ref} from 'vue';


const __default__ = {
    name: "MobileFix"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const FIX_CLASS = "mobile-online-chat-opened";

let initialScrollTop = ref(0);

let classList = computed(() => {
    return document.body.parentElement.classList;
});

onBeforeUnmount(() => {
    classList.value.remove(FIX_CLASS);
    removeMobileMeta();

    setTimeout(() => {
        window.scrollTo({
            top: initialScrollTop.value
        });
    }, 100);
});

function addMobileMeta() {
    let head = document.head;
    if (head != null) {
        let currentMetas = head.querySelectorAll("meta[name=viewport]");
        let currentMeta: HTMLMetaElement;
        if (currentMetas.length > 0) {
            currentMeta = currentMetas.item(currentMetas.length - 1) as any;
        }
        let scale = 1;
        if (currentMeta) {
            let content = currentMeta.getAttribute("content");
            if (content) {
                for (let item of content.split(",")) {
                    let itemSplit = item.toLowerCase().trim().split("=");
                    if (itemSplit.length == 2) {
                        if (itemSplit[0] == "initial-scale") {
                            let value = parseFloat(itemSplit[1]);
                            if (value > 1 || value < 1) {
                                scale = value;
                                //return;
                            }
                        }
                    }
                }
            }
        }


        let meta1 = document.createElement("meta");
        meta1.setAttribute("id", "supportMeta1");
        let v = `width=device-width, height=device-height, initial-scale=${scale}, user-scalable=0, minimum-scale=${scale}, maximum-scale=${scale}`;
        meta1.setAttribute("content", v);
        meta1.setAttribute("name", "viewport");
        head.appendChild(meta1);
        meta1.setAttribute("content", v);
    }
}

function removeMobileMeta() {
    var head = document.head;
    if (head != null) {
        var meta = document.getElementById("supportMeta1");
        if (meta != null) {
            var initialMeta = document.querySelectorAll("meta[name=viewport]:not(#supportMeta1)");

            head.removeChild(meta);

            if (!initialMeta.length) {
                var fixMeta = document.createElement("meta");
                var v = "user-scalable=1";
                fixMeta.setAttribute("content", v);
                fixMeta.setAttribute("name", "viewport");
                head.appendChild(fixMeta);
                fixMeta.setAttribute("content", v);
                fixMeta.setAttribute("content", "");
                head.removeChild(fixMeta);
            } else {
                for (var i = 0; i < initialMeta.length; i++) {
                    if (initialMeta[i] != null) {
                        var parent = initialMeta[i].parentNode;
                        if (parent) {
                            parent.removeChild(initialMeta[i]);
                            parent.appendChild(initialMeta[i]);
                        }
                    }
                }
            }
        }
    }
}

initialScrollTop.value = window.scrollY;
classList.value.add(FIX_CLASS);
addMobileMeta();

return (_ctx: any,_cache: any) => {
  return null
}
}

})