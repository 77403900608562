import {CHAT_ROOT_CLASS, CHAT_ROOT_CLASS_INHERIT} from "~/chat/ts/Constants";
import {ConfigStore} from "~/chat/ts/store/Config";
import {App} from "vue";

export default class ChatComponentMount {
    public static mount(container: IMyHtmlElement, app: App, className: string[] = [CHAT_ROOT_CLASS_INHERIT], replaceContainer = false) {
        if (container.app) {
            container.app.unmount();
            delete container.app;
        }
        container.app = app;
        let root = document.createElement("div");
        root.classList.add(CHAT_ROOT_CLASS);
        root.classList.add(ConfigStore.rootClass.value);
        for (let item of className) {
            root.classList.add(item);
        }

        let div = document.createElement("div");
        root.appendChild(div);
        container.innerHTML = "";
        if (replaceContainer) {
            if (container.parentNode) {
                container.parentNode.replaceChild(root, container);
            }
            //container.replaceWith(root);
        } else {
            container.appendChild(root);
        }
        this.fixRoot(root);
        app.mount(div);
    }

    private static fixRoot(root: HTMLElement) {
        //Сделали, чтобы умники не перехватывали наши формы оплаты
        let events = ["submit", "touchmove"];
        for (let event of events) {
            root.addEventListener(event, function (e) {
                e.stopPropagation();
            });
        }
    }
}

interface IMyHtmlElement extends HTMLElement {
    app?: App;
}