import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, isRef as _isRef, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

import {Component, ComponentPublicInstance, computed, onMounted, onUpdated, ref, watch} from 'vue';
import {WidgetStore} from "~/chat/ts/store/Widget";
import {
    INITIAL_WIDGET_POSITION_AT_WINDOW_CENTER,
    INITIAL_WIDGET_POSITION_NEAR_TRIGGER,
    ITriggerOffset,
    ITriggerSide,
    TRIGGER_MENU_HIDE_CLICK,
    TRIGGER_MENU_HIDE_MOUSEOUT,
    TRIGGER_MENU_SHOW_CLICK,
    TRIGGER_MENU_SHOW_MOUSEOVER,
    TRIGGER_OFFSET_SIDE_PRIMARY,
    TRIGGER_OFFSET_SIDE_SECONDARY,
    TRIGGER_SIDE_BOTTOM,
    TRIGGER_SIDE_CENTER,
    TRIGGER_SIDE_LEFT,
    TRIGGER_SIDE_RIGHT,
    TRIGGER_SIDE_TOP,
    TRIGGER_TYPE_CLASSIC,
    TRIGGER_TYPE_HIDDEN,
    TRIGGER_TYPE_IMAGE,
    TRIGGER_TYPE_ROUND
} from "~/chat/ts/data/ISiteParams";
import ClassicTrigger from "~/chat/vue/trigger/ClassicTrigger.vue";
import Dictionary from "~/ts/library/Dictionary";
import WindowHelper from "~/ts/library/WindowHelper";
import RoundTrigger from "~/chat/vue/trigger/RoundTrigger.vue";
import ImageTrigger from "~/chat/vue/trigger/ImageTrigger.vue";
import {TriggerStore} from "~/chat/ts/store/Trigger";
import Delay from "~/ts/library/Delay";
import {ConfigStore} from "~/chat/ts/store/Config";
import {TRIGGER_TYPES} from "~/cabinet/vue/client/online/config/design/tabs/trigger/Interfaces";
import Random from "~/ts/library/Random";
import HiddenTrigger from "~/chat/vue/trigger/HiddenTrigger.vue";
import ChatStyle from "~/chat/vue/misc/ChatStyle.vue";
import AnalyticCounters from "~/chat/ts/service/AnalyticCounters";
import {__} from "~/ts/library/Translate";
import Events from "~/ts/library/Events";
import {addDisposableEvent} from "~/ts/vuePlugins/useDisposableEvent";


const __default__ = {
    name: "ChatTrigger"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    id: { default: "supportTrigger" }
  },
  setup(__props: any) {

let props = __props;

let additionalStyles = ref<Dictionary<string>>({});
let inlineStyles = ref<Dictionary<string>>({});
let defaultBubbleText = ref<string>();
let mouseOver = ref(false);
let isTriggerHiddenBecauseOfScroll = ref(false);
let trigger = ref<ComponentPublicInstance>();

let triggerConfig = TriggerStore.triggerParams;


let isTriggerOnPage = computed(() => {
    return !WidgetStore.isOpened.value && triggerConfig.value && triggerComponent.value;
});

let isHideOnScrollEnabled = computed(() => {
    return ConfigStore.isMobile.value && !!triggerConfig.value.hideOnScrollEnabled;
});

onMounted(() => {
    //запускаем, чтобы отпозицинировать виджет
    triggerUpdated();
});

let isTriggerHidden = computed(() => {
    return TriggerStore.isTriggerHidden.value || isTriggerHiddenBecauseOfScroll.value;
});

async function onClick() {
    AnalyticCounters.send("Click on widget button", __("EVENT_CLIENT_CLICK_ON_WIDGET_BUTTON"));


    let value: boolean;
    if (menuConfig.value) {
        if (TriggerStore.isTriggerMenuOpened.value) {
            if (TriggerStore.triggerParams.value.triggerType == TRIGGER_TYPE_ROUND && menuConfig.value.hide == TRIGGER_MENU_HIDE_CLICK) {
                //закрываем по клику на ярлык только на круглых кнопках
                //TODO: надо с этим что-то делать. Настройка не выведена в личный кабинет, и вся эта хуйня оч неявная
                value = false;
            }
        } else if (menuConfig.value.show == TRIGGER_MENU_SHOW_CLICK) {
            value = true;
        } else if (menuConfig.value.show == TRIGGER_MENU_SHOW_MOUSEOVER && mouseOver.value) {
            value = true;
        }
    }
    if (value != null) {
        TriggerStore.SET_TRIGGER_MENU_OPENED(value);
    } else {
        if (WidgetStore.chatTab.value != null) {
            WidgetStore.openChatTab();
        } else {
            WidgetStore.open();
        }
    }
}

async function isCanContinueMouseEvent() {
    try {
        await Delay.make(10, "triggerMouseEvent", true);
        return true;
    } catch (e) {
        return false;
    }
}

async function onMouseOver() {
    if (await isCanContinueMouseEvent()) {
        if (!mouseOver.value) {
            if (!WidgetStore.isOpened.value) {
                mouseOver.value = true;
                if (menuConfig.value) {
                    if (menuConfig.value.show == TRIGGER_MENU_SHOW_MOUSEOVER) {
                        try {
                            await Delay.make(1, "triggerMouseToggle", true);
                            TriggerStore.SET_TRIGGER_MENU_OPENED(true);
                        } catch (e) {

                        }
                    }
                }
            }
        }
    }
}

async function onMouseOut() {
    if (await isCanContinueMouseEvent()) {
        mouseOver.value = false;
        if (menuConfig.value) {
            if (TriggerStore.isTriggerMenuOpened.value) {
                if (menuConfig.value.hide == TRIGGER_MENU_HIDE_MOUSEOUT) {
                    try {
                        await Delay.make(500, "triggerMouseToggle", true);
                        TriggerStore.SET_TRIGGER_MENU_OPENED(false);
                    } catch (e) {

                    }
                }
            }
        }
    }
}

let menuConfig = computed(() => {
    return TriggerStore.hasMenu.value ? TriggerStore.triggerMenuDisplayOptions.value : null;
});


let triggerType = computed(() => {
    return TRIGGER_TYPES.find(type => triggerConfig.value.triggerType == type.id);
});

let style = computed(() => {
    let result: Dictionary<string> = {};
    if (!TriggerStore.mobileTriggerMenuDisplayed.value) {
        let config = triggerConfig.value;
        let offset: Dictionary<ITriggerOffset> = config.offset;

        let transform = "";
        if (ConfigStore.needScaleFix.value) {
            result.zoom = WindowHelper.scaleZoomFix.toString();
            if (WindowHelper.isWindowOrViewportEventRun) {
                result.display = "none !important";
            }
            if (isOnSides([TRIGGER_SIDE_LEFT, TRIGGER_SIDE_BOTTOM]) || isOnSides([TRIGGER_SIDE_CENTER, TRIGGER_SIDE_BOTTOM])) {
                transform = `translateY(${WindowHelper.scaleBottomFix}) translateX(${WindowHelper.scaleLeftFix})`;
            } else if (isOnSides([TRIGGER_SIDE_RIGHT, TRIGGER_SIDE_BOTTOM])) {
                transform = `translateY(${WindowHelper.scaleBottomFix}) translateX(${WindowHelper.scaleRightFix})`;
            } else if (isOnSides([TRIGGER_SIDE_LEFT, TRIGGER_SIDE_TOP])) {
                transform = `translateY(${WindowHelper.scaleTopFix}) translateX(${WindowHelper.scaleLeftFix})`;
            } else if (isOnSides([TRIGGER_SIDE_RIGHT, TRIGGER_SIDE_TOP])) {
                transform = `translateY(${WindowHelper.scaleTopFix}) translateX($${WindowHelper.scaleRightFix})`;
            }
        }
        transform += " ";

        for (let key in offset) {
            if (offset.hasOwnProperty(key)) {
                let item = offset[key];
                let side = item.side;
                let value = item.value;
                if (key == TRIGGER_OFFSET_SIDE_PRIMARY) {
                    let type = triggerType.value;
                    if (type) {
                        if (!type.isCanPrimaryOffset) {
                            value = 0;
                        }
                    }
                }
                if (side == TRIGGER_SIDE_CENTER) {
                    let windowSize = WindowHelper.getSize();
                    if ([TRIGGER_SIDE_RIGHT, TRIGGER_SIDE_LEFT].indexOf(config.offset.primary.side) > -1) {
                        side = TRIGGER_SIDE_TOP;
                        value = windowSize.height / 2;
                        transform += "translateY(-50%)";
                    } else {
                        side = TRIGGER_SIDE_LEFT;
                        value = windowSize.width / 2;
                        transform += "translateX(-50%)";
                    }
                    if (ConfigStore.needScaleFix.value) {
                        value /= WindowHelper.scaleZoomFix;
                    }

                }

                result[side] = `${value}px`;
            }
        }
        if (transform.trim().length > 0) {
            result.transform = transform;
        }
    } else {
        result.display = "none";
    }
    return {
        [`.${ConfigStore.rootClass.value} #supportTrigger`]: {
            ...result,
            ...additionalStyles.value
        }
    };
});

function isOnSides(sides: ITriggerSide[]): boolean {
    let offset = triggerConfig.value.offset;
    let configSides = [
        offset[TRIGGER_OFFSET_SIDE_PRIMARY].side,
        offset[TRIGGER_OFFSET_SIDE_SECONDARY].side
    ];
    if (configSides[1] == TRIGGER_SIDE_CENTER) {
        if ([TRIGGER_SIDE_TOP, TRIGGER_SIDE_BOTTOM].indexOf(configSides[0]) > -1) {
            configSides[1] = TRIGGER_SIDE_LEFT;
        } else {
            configSides[1] = TRIGGER_SIDE_TOP;
        }
    }
    for (let side of sides) {
        if (configSides.indexOf(side) == -1) {
            return false;
        }
    }
    return true;
}

let className = computed(() => {
    let offset = triggerConfig.value.offset;
    let primary = offset.primary.side;
    let result = [
        'trigger',
        'top-z-index',
        'cursor-pointer',
        'trigger-type',
        `primary-${primary}`,
        //`one-of-${primary}`,
        //`one-of-${offset.secondary.side}`,
        `secondary-${offset.secondary.side}`
    ];
    if (isTriggerHidden.value) {
        result.push('hidden');
    }
    if ([TRIGGER_SIDE_TOP, TRIGGER_SIDE_BOTTOM].indexOf(primary) > -1) {
        result.push("primary-top-bottom");
    } else {
        result.push("primary-left-right");
    }
    return result;
});

let triggerComponent = computed<Component>(() => {
    let type = triggerConfig.value.triggerType;
    let components: Dictionary<Component> = {
        [TRIGGER_TYPE_CLASSIC]: ClassicTrigger,
        [TRIGGER_TYPE_ROUND]: RoundTrigger,
        [TRIGGER_TYPE_IMAGE]: ImageTrigger,
        [TRIGGER_TYPE_HIDDEN]: HiddenTrigger
    };
    return components[type] ? components[type] : null;
});

onUpdated(() => triggerUpdated());


let widgetSize = computed(() => {
    let width = WidgetStore.width.value;
    let height = WidgetStore.height.value;

    return {width, height};
});


let lastTriggerBoundingRect: DOMRect | ClientRect;

function getTriggerBoundingRect(): DOMRect | ClientRect | null {
    if (trigger.value) {
        let $el = trigger.value.$el as HTMLElement;
        lastTriggerBoundingRect = $el.getBoundingClientRect();
    }
    return lastTriggerBoundingRect;
}

let triggerUpdatedFirstTime = ref(true);

async function triggerUpdated() {
    if (!WidgetStore.positionChangedManualy.value) {
        try {
            //ждем анимаций
            if (!triggerUpdatedFirstTime.value) {
                //было 300
                await Delay.make(50, "triggerUpdatedDelay", true);
            } else {
                //в первый раз запускаем сразу же, чтобы отпозиционировать виджет как надо
                triggerUpdatedFirstTime.value = false;
            }


            let rect = getTriggerBoundingRect();
            if (rect) {
                let offsetConfig = triggerConfig.value.offset;
                let x: number;
                let y: number;
                let primarySide = offsetConfig.primary.side;
                let secondarySide = offsetConfig.secondary.side;
                let windowSize = WindowHelper.getSize();

                let width = widgetSize.value.width;
                let height = widgetSize.value.height;

                let initialWidgetPosition = ConfigStore.design.value.initialWidgetPosition;

                if (secondarySide == TRIGGER_SIDE_BOTTOM || primarySide == TRIGGER_SIDE_BOTTOM) {
                    y = rect.bottom - height;
                } else {
                    y = rect.top;
                }

                if (primarySide == TRIGGER_SIDE_RIGHT || secondarySide == TRIGGER_SIDE_RIGHT) {
                    x = rect.right - width;
                } else {
                    x = rect.left;
                }
                if (secondarySide == TRIGGER_SIDE_CENTER) {
                    if ([TRIGGER_SIDE_LEFT, TRIGGER_SIDE_RIGHT].indexOf(primarySide) > -1) {
                        y = (windowSize.height - height) / 2;
                    } else {
                        x = (windowSize.width - width) / 2;
                    }
                }
                if (initialWidgetPosition == INITIAL_WIDGET_POSITION_NEAR_TRIGGER) {
                    if (primarySide == TRIGGER_SIDE_BOTTOM) {
                        y = rect.top - height;
                    } else if (primarySide == TRIGGER_SIDE_LEFT) {
                        x = rect.right;
                    } else if (primarySide == TRIGGER_SIDE_TOP) {
                        y = rect.bottom;
                    } else if (primarySide == TRIGGER_SIDE_RIGHT) {
                        x = rect.left - width;
                    }
                } else if (initialWidgetPosition == INITIAL_WIDGET_POSITION_AT_WINDOW_CENTER) {
                    let windowSize = WindowHelper.getSize();
                    x = (windowSize.width - width) / 2;
                    y = (windowSize.height - height) / 2;
                }
                WidgetStore.updatePosition({
                    type: "top",
                    value: y
                });
                WidgetStore.updatePosition({
                    type: "left",
                    value: x
                });
            }
        } catch (e) {

        }
    }
    WidgetStore.triggerPositionInitialized.value = true;
}

(() => {
    let id = Random.uid();
    addDisposableEvent(
        Events.addEventListener(
            "scroll",
            window,
            async () => {
                if (isHideOnScrollEnabled.value) {
                    isTriggerHiddenBecauseOfScroll.value = true;
                    try {
                        await Delay.make(ConfigStore.setup.value.returnMobileTriggerTimeout, id, true);
                        isTriggerHiddenBecauseOfScroll.value = false;
                    } catch (e) {

                    }
                }
            },
            {
                passive: true
            }
        )
    );

    addDisposableEvent(Events.addEventListener(
        "resize", window, async () => {
            try {
                await Delay.make(100, "triggerUpdatedOnWindowResize", true);
                triggerUpdated();
            } catch (e) {

            }
        }
    ));

    if (ConfigStore.isMobile.value) {
        addDisposableEvent(
            Events.addEventListener(
                "focusin",
                document,
                (e: FocusEvent) => {
                    let target = e.target as HTMLElement;
                    if (target.matches("input:not([type='radio']):not([type='checkbox']),textarea")) {
                        TriggerStore.hideTrigger("inputFocused");
                    }
                }
            )
        );
        addDisposableEvent(
            Events.addEventListener(
                "focusout",
                document,
                () => {
                    TriggerStore.showTrigger("inputFocused");
                }
            )
        );
    }
})();

watch(isTriggerOnPage, (value) => {
    if (!value) {
        mouseOver.value = false
    }
});

watch(widgetSize, () => triggerUpdated(), {deep: true});



return (_ctx: any,_cache: any) => {
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'pointer-events-none': !_unref(isTriggerOnPage)})
  }, [
    _createVNode(_Transition, { name: "list" }, {
      default: _withCtx(() => [
        (_unref(isTriggerOnPage))
          ? _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(triggerComponent)), {
              key: 0,
              id: _ctx.id,
              class: _normalizeClass(_unref(className)),
              config: _unref(triggerConfig),
              "additional-styles": _unref(additionalStyles),
              "onUpdate:additionalStyles": _cache[0] || (_cache[0] = ($event: any) => (_isRef(additionalStyles) ? (additionalStyles).value = $event : additionalStyles = $event)),
              "inline-styles": _unref(inlineStyles),
              "onUpdate:inlineStyles": _cache[1] || (_cache[1] = ($event: any) => (_isRef(inlineStyles) ? (inlineStyles).value = $event : inlineStyles = $event)),
              style: _normalizeStyle(_unref(inlineStyles)),
              onDefaultBubbleText: _cache[2] || (_cache[2] = ($event: any) => (_isRef(defaultBubbleText) //@ts-ignore
 ? defaultBubbleText.value = $event : defaultBubbleText = $event)),
              ref_key: "trigger",
              ref: trigger,
              onUpdated: triggerUpdated
            }, null, 40, ["id", "class", "config", "additional-styles", "inline-styles", "style"])), [
              [
                _directive_on_native,
                onClick,
                "click",
                { stop: true }
              ],
              [_directive_on_native, onMouseOver, "mouseover"],
              [_directive_on_native, onMouseOut, "mouseout"]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(ChatStyle, {
      id: "online-chat-trigger",
      prepend: "",
      css: _unref(style),
      "append-root-selector": false
    }, null, 8, ["css"])
  ], 2))
}
}

})