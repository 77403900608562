import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "trigger-menu" }
const _hoisted_2 = { class: "filler" }

import SvgIcon from "~/chat/vue/misc/SvgIcon.vue";
import TriggerPopup from "~/chat/vue/trigger/TriggerPopup.vue";
import TriggerMenuItem from "~/chat/vue/trigger/menu/TriggerMenuItem.vue";
import {useTriggerMenu, useTriggerMenuProps} from "~/chat/vue/trigger/menu/useTriggerMenu";
import {computed} from "vue";
import {TriggerStore} from "~/chat/ts/store/Trigger";


const __default__ = {
    name: "DropdownTriggerMenu"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useTriggerMenuProps()
},
  setup(__props) {

let props = __props;

let {menuItems} = useTriggerMenu(props);

let useTextColor = computed(() => !!TriggerStore.triggerMenuForDevice.value.useTextColorForIcons);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TriggerPopup, {
    "overflow-hidden": "",
    arrow: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuItems), (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item.id
          }, [
            _createVNode(TriggerMenuItem, {
              item: item,
              class: "d-flex horizontal"
            }, {
              default: _withCtx(({ descr }) => [
                _createVNode(SvgIcon, {
                  class: "icon",
                  "icon-name": item.iconName,
                  style: _normalizeStyle({color: _unref(useTextColor) ? null : `${item.color1} !important`}),
                  "icon-style": item.iconStyle
                }, null, 8, ["icon-name", "style", "icon-style"]),
                _createElementVNode("div", _hoisted_2, _toDisplayString(descr), 1)
              ]),
              _: 2
            }, 1032, ["item"])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}
}

})