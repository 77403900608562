import {WidgetStore} from "~/chat/ts/store/Widget";
import {WidgetDataInterface} from "~/chat/ts/store/ChatTab";
import {ComponentPublicInstance} from "vue";

export default class ChatApiResult {
    public data: any;

    constructor(data: { code: number, descr: string }) {
        this.data = data == null ? {} : data;
    }

    public isSuccess(): boolean {
        return this.getCode() == 1;
    }

    public getCode(): Number {
        return parseInt(this.getParam("code", 0));
    }

    public getParam(key: string | string[] = null, defaultValue: any = null): any {
        let result: object = this.data;
        if (key != null) {
            if (typeof key == "string") {
                key = [key];
            }

            for (let i = 0; i < key.length; i++) {
                if (typeof result == "object") {
                    if (result.hasOwnProperty(key[i])) {
                        result = (result as any)[key[i]];
                        continue;
                    }
                }
                result = defaultValue;
                break;
            }
        }

        return result;
    }

    public getData(key: string | string[] = null, defaultValue: any = null): any {
        let keys = ["data"];
        if (key) {
            if (typeof key == "string") {
                key = [key];
            }
            keys = keys.concat(key);
        }
        return this.getParam(keys, defaultValue);
    }

    public getDescr(): string {
        return this.getParam("descr", "").toString();
    }

    //vue нужен для того, чтобы определить - внутри виджета показывать или в body
    public showMessage(vue: ComponentPublicInstance, escape: boolean = true) {
        WidgetStore.showModalMessage({text: this.getDescr(), escape, vue});
    }

    public get widgetData(): WidgetDataInterface | null {
        return this.getData("widgetData");
    }
}

