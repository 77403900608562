import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "lh-primary notification-message-list"
}

import Htmlify from "~/chat/vue/misc/ChatHtmlify.vue";
import MessageButton from "~/chat/vue/widget/tabs/chat/MessageButton.vue";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import {IMessageButton} from "~/chat/ts/data/chat/IMessage";
import {WidgetStore} from "~/chat/ts/store/Widget";
import {__} from "~/ts/library/Translate";
import StringHelper from "~/ts/library/StringHelper";
import {ConfigStore} from "~/chat/ts/store/Config";
import {useNotification, AbstractNotificationItem} from "~/chat/vue/notification/useNotification";


const __default__ = {
    name: "NotificationMessageList"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    messages: {}
  },
  setup(__props: any) {

let props = __props;

let {} = useNotification();

function onButtonClick() {
    let lastMessage = props.messages
        .filter(item => !!item.originalMessageWrapper)
        .reverse()[0];
    if (lastMessage) {
        MessageStorage.markAsReaded(lastMessage.originalMessageWrapper.rawMessage);
    }
}

function getButtons(item: AbstractNotificationItem): IMessageButton[] {
    if (ConfigStore.isShowMessageButtonsInNotification.value) {
        let result: IMessageButton[] = [...item.originalMessageWrapper?.buttons ?? []];
        let maxButtons = 5;
        let count = result.length;
        if (count > maxButtons + 1) {
            result.splice(maxButtons);
            result.push({
                text: `+ ` + StringHelper.capitalize(__("MORE_D", count - maxButtons)),
                actions: [
                    {
                        type: "openWidgetTab",
                        params: {
                            tabId: WidgetStore.chatTab.value.id
                        }
                    }
                ]
            });
        }

        return result;
    } else {
        return [];
    }
}


return (_ctx: any,_cache: any) => {
  const _directive_on_native = _resolveDirective("on-native")!

  return (_ctx.messages)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id
          }, [
            (!item.isHiddenText)
              ? (_openBlock(), _createBlock(Htmlify, {
                  key: 0,
                  value: item.text,
                  nl2br: "",
                  emoji: "",
                  bbcode: ""
                }, null, 8, ["value"]))
              : _createCommentVNode("", true),
            (index === _ctx.messages.length - 1)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (getButtons(item).length)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass({
                        'mt-3': !item.isHiddenText
                    })
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getButtons(item), (button, index) => {
                          return _withDirectives((_openBlock(), _createBlock(MessageButton, {
                            key: index,
                            button: button,
                            round: false,
                            class: _normalizeClass({
                            'd-block w-100': true,
                            'mt-2': !item.isHiddenText || index > 0
                        })
                          }, null, 8, ["button", "class"])), [
                            [
                              _directive_on_native,
                              () => onButtonClick,
                              "click",
                              { stop: true }
                            ]
                          ])
                        }), 128))
                      ], 2))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})