import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filler text-center" }

import {WidgetStore} from "~/chat/ts/store/Widget";


const __default__ = {
    name: "WidgetLoaderWrapper"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

function closeWidget() {
    WidgetStore.close();
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "widget-root absolute-full-size d-flex align-items-center horizontal widget-header-color widget-header-background",
    onClick: closeWidget
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})