import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "target"]

import {computed} from 'vue';
import {
    IMenuItem,
    TRIGGER_MENU_ITEM_TYPE_CHAT,
    TRIGGER_MENU_ITEM_TYPE_CHAT_BOT,
    TRIGGER_MENU_ITEM_TYPE_FACEBOOK,
    TRIGGER_MENU_ITEM_TYPE_INSTAGRAM,
    TRIGGER_MENU_ITEM_TYPE_JAVASCRIPT,
    TRIGGER_MENU_ITEM_TYPE_ODNOKLASSNIKI,
    TRIGGER_MENU_ITEM_TYPE_REVIEWS,
    TRIGGER_MENU_ITEM_TYPE_TAB,
    TRIGGER_MENU_ITEM_TYPE_TELEGRAM,
    TRIGGER_MENU_ITEM_TYPE_VIBER,
    TRIGGER_MENU_ITEM_TYPE_VKONTAKTE,
    TRIGGER_MENU_ITEM_TYPE_WHATSAPP
} from "~/chat/ts/data/ISiteParams";
import {
    DEVICE_DESKTOP
} from "~/ts/library/Device";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import {ConfigStore} from "~/chat/ts/store/Config";
import Device from "~/ts/library/Device";
import {WidgetStore} from "~/chat/ts/store/Widget";
import AnalyticCounters from "~/chat/ts/service/AnalyticCounters";
import ApiCallback, {API_CALLBACK_OPEN_MENU_LINK} from "~/chat/ts/service/api/methods/ApiCallback";
import SafeEval from "~/ts/library/SafeEval";
import {REVIEWS_TRIGGER_MENU_FORM} from "~/chat/ts/Constants";
import openReviewsTab from "~/chat/ts/service/api/methods/OpenReviewsTab";
import startBot from "~/chat/ts/service/api/methods/StartBot";
import closeMenu from "~/chat/ts/service/api/methods/CloseMenu";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import SendTextMessageToClient from "~/chat/ts/service/autoActions/actions/SendTextMessageToClient";
import IntegrationLinks from "~/chat/ts/service/IntegrationLinks";
import {TELEGRAM, VIBER, VK_GROUP, WHATSAPP} from "~/chat/ts/data/IntergrationPlatforms";


const __default__ = {
    name: "TriggerMenuItem"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    item: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let isChatTab = computed(() => {
    return props.item && props.item.type == TRIGGER_MENU_ITEM_TYPE_CHAT;
});

let descr = computed(() => {
    let result: string;
    if (props.item) {
        result = props.item.descr;
        let relative = props.item.descrRelativeToMode;
        if (relative) {
            result = OperatorsStore.isOffline.value ? relative.offline : relative.online;
        }
    }
    return result;
});

function onClick(e: MouseEvent) {
    emits('click');
    if (props.item) {
        if (!href.value) {
            e.preventDefault();
        } else {
            ApiCallback.emit(API_CALLBACK_OPEN_MENU_LINK, href.value);
        }

        let item = props.item;
        let type = item.type;

        if (type == TRIGGER_MENU_ITEM_TYPE_CHAT) {
            WidgetStore.closeSideMenu();
            WidgetStore.openChatTab();
            if (ChatTabStore.isOfflineFormDisplayed.value) {
                if (MessageStorage.getUnreadedMessageCount()) {
                    ChatTabStore.toggleOfflineForm(false);
                }
            }
        } else if (type == TRIGGER_MENU_ITEM_TYPE_REVIEWS) {
            WidgetStore.closeSideMenu();
            openReviewsTab(item.params.content == REVIEWS_TRIGGER_MENU_FORM);
        } else if (type == TRIGGER_MENU_ITEM_TYPE_TAB) {
            WidgetStore.closeSideMenu();
            WidgetStore.openById({id: item.params.link});
        } else if (type == TRIGGER_MENU_ITEM_TYPE_CHAT_BOT) {
            WidgetStore.closeSideMenu();
            WidgetStore.openChatTab();
            startBot(item.params.schemeId);
        }

        if (item.countersAction) {
            AnalyticCounters.send(item.countersAction, null, null, true);
        }
        if (type == TRIGGER_MENU_ITEM_TYPE_JAVASCRIPT) {
            SafeEval.make(item.params.javascript);
        } else if (item.onClick) {
            SafeEval.make(item.onClick);
        }

        closeMenu();
    }
}

let target = computed(() => {
    return href.value && href.value.indexOf("http") === 0 ? "_blank" : null;
});

let href = computed(() => {
    let href: string;
    if (props.item) {
        let item = props.item;
        let type = item.type;
        let params = item.params;
        let platformId: string;
        if (type == TRIGGER_MENU_ITEM_TYPE_VKONTAKTE) {
            //let gid = params.vkGroupInfo ? params.vkGroupInfo.gid : params.link.replace(/\D/g, "");
            href = `https://vk.${params.displayType == "messages" ? 'me' : 'com'}/${params.link}`;
            platformId = VK_GROUP;
        } else if (type == TRIGGER_MENU_ITEM_TYPE_FACEBOOK) {
            if (params.displayType == "messages") {
                if (ConfigStore.device.value != DEVICE_DESKTOP) {
                    href = `https://m.facebook.com/messages/compose?ids=${params.link}`;
                } else {
                    href = `https://facebook.com/messages/t/${params.link}`;
                }
                //platformId = "fbGroup";
            } else {
                href = `https://facebook.com/${params.link}`;
            }
        } else if (type == TRIGGER_MENU_ITEM_TYPE_TELEGRAM) {
            href = `https://t.me/${params.link}`;
            platformId = TELEGRAM;
        } else if (type == TRIGGER_MENU_ITEM_TYPE_VIBER) {
            if (params.linkType == "number") {
                params.link = params.link.split("+").join("");
                if (!ConfigStore.isMobile.value || Device.ios()) {
                    href = "viber://chat?number=+";
                } else {
                    href = "viber://chat?number=";
                }
            } else {
                href = "viber://pa?chatURI=";
            }
            platformId = VIBER;
            href += params.link;
        } else if (type == TRIGGER_MENU_ITEM_TYPE_WHATSAPP) {
            //href = "https://api.whatsapp.com/send?phone=" + params.link;
            //https://api.whatsapp.com/send/?phone=79254291630&text=%D0%94%D0%B0%D0%BD%D0%BD%D0%BE%D0%B5+%D1%81%D0%BE%D0%BE%D0%B1%D1%89%D0%B5%D0%BD%D0%B8%D0%B5+%D0%BE%D1%82%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%BE+%D1%81+%D1%81%D0%B0%D0%B9%D1%82%D0%B0+https%3A%2F%2Fwww.mini-borishof.ru%2F&app_absent=0

            /*
            href = `whatsapp://send?phone=${params.link}`;
            if (typeof params.initialText == "string" && params.initialText.length) {
                href += "&text=" + encodeURIComponent(params.initialText);
            }*/

            href = "https://wa.me/" + params.link;
            if (typeof params.initialText == "string" && params.initialText.length) {
                let initialText = params.initialText;
                initialText = SendTextMessageToClient.replaceTextVariables(initialText, null, true);
                href += "?text=" + encodeURIComponent(initialText);
            }
            platformId = WHATSAPP;

        } else if (type == TRIGGER_MENU_ITEM_TYPE_INSTAGRAM) {
            href = "https://instagram.com/" + params.link;
            //platformId = "instagram";
        } else if (type == TRIGGER_MENU_ITEM_TYPE_ODNOKLASSNIKI) {
            href = "https://ok.ru/group/" + params.link;
            //platformId = "okGroup";
        } else if (type == "url") {
            let link = params.link;
            if (typeof link == "string" && link.length) {
                href = link;
            }
        }
        if (platformId) {
            href = IntegrationLinks.makeLink(platformId, href);
        }
    }
    return href;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    class: "link",
    href: _unref(href),
    target: _unref(target),
    onClick: _withModifiers(onClick, ["stop"])
  }, [
    _renderSlot(_ctx.$slots, "default", {
      descr: _unref(descr),
      isChatTab: _unref(isChatTab)
    })
  ], 8, _hoisted_1))
}
}

})