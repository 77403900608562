import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inner" }

import TriggerBubble from "~/chat/vue/notification/TriggerBubble.vue";
import TriggerMenu from "~/chat/vue/trigger/menu/TriggerMenu.vue";
import {TriggerStore} from "~/chat/ts/store/Trigger";
import {TRIGGER_MENU_TYPE_MOBILE} from "~/chat/ts/data/ISiteParams";


const __default__ = {
    name: "TriggerDropdownContainer"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    defaultBubbleText: {},
    triggerWidth: {}
  },
  setup(__props: any) {

let props = __props;

const mobileTriggerMenuTypeId = TRIGGER_MENU_TYPE_MOBILE;

let isMenuOpened = TriggerStore.isTriggerMenuOpened;

return (_ctx: any,_cache: any) => {
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'dropdown-container': true}),
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_Transition, { name: "list" }, {
        default: _withCtx(() => [
          (!_unref(isMenuOpened))
            ? _withDirectives((_openBlock(), _createBlock(TriggerBubble, {
                key: 0,
                "default-text": _ctx.defaultBubbleText
              }, null, 8, ["default-text"])), [
                [
                  _directive_on_native,
                  void 0,
                  "mouseover",
                  { stop: true }
                ],
                [
                  _directive_on_native,
                  void 0,
                  "mouseout",
                  { stop: true }
                ]
              ])
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(TriggerMenu, {
        "disallowed-display-types": [_unref(mobileTriggerMenuTypeId)],
        "trigger-width": _ctx.triggerWidth
      }, null, 8, ["disallowed-display-types", "trigger-width"])
    ])
  ]))
}
}

})