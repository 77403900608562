import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import SvgIcon from "~/chat/vue/misc/SvgIcon.vue";


const __default__ = {
    name: "CloseIcon"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  emits: ["click"],
  setup(__props, { emit: __emit }) {

let emits = __emit;

return (_ctx: any,_cache: any) => {
  const _directive_on_native = _resolveDirective("on-native")!

  return _withDirectives((_openBlock(), _createBlock(SvgIcon, {
    class: "cursor-pointer",
    "icon-name": "times",
    "icon-style": "light"
  }, null, 512)), [
    [_directive_on_native, () => _unref(emits)('click'), "click"]
  ])
}
}

})