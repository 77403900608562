import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {computed} from 'vue';
import Dictionary from "~/ts/library/Dictionary";
import MyStyle from "~/cabinet/vue/interface/MyStyle.vue";
import {ConfigStore} from "~/chat/ts/store/Config";


const __default__ = {
    name: "ChatStyle"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    id: {},
    css: {},
    cssString: { default: "" },
    appendRootSelector: { type: Boolean, default: true },
    prepend: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;

let computedId = computed(() => {
    return `${ConfigStore.apiMethod.value}_${props.id}`;
});

let rootSelector = computed(() => {
    return `.${ConfigStore.rootClass.value}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyStyle, {
    id: _unref(computedId),
    css: _ctx.css,
    "css-string": _ctx.cssString,
    prepend: _ctx.prepend,
    "root-selector": _ctx.appendRootSelector ? _unref(rootSelector) : null
  }, null, 8, ["id", "css", "css-string", "prepend", "root-selector"]))
}
}

})