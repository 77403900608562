import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {computed, onMounted, watch} from 'vue';
import {ConfigStore} from "~/chat/ts/store/Config";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import {WidgetStore} from "~/chat/ts/store/Widget";
import ChatStyle from "~/chat/vue/misc/ChatStyle.vue";


const __default__ = {
    name: "BodyClassHandler"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const BODY_CLASS_MOBILE = "supportMobile";
const BODY_CLASS_DESKTOP = "supportDesktop";
const BODY_CLASS_ONLINE = "supportOnline";
const BODY_CLASS_OFFLINE = "supportOffline";
const BODY_CLASS_OPENED = "supportOpened";
const BODY_CLASS_NOT_LOADED = "supportNotLoaded";

onMounted(() => applyBodyClass());

let initializedClass = computed(() => {
    return `${ConfigStore.apiMethod.value}-initialized`;
});

let bodyClass = computed(() => {
    let result = [
        initializedClass.value
    ];
    result.push(ConfigStore.isMobile.value ? BODY_CLASS_MOBILE : BODY_CLASS_DESKTOP);
    result.push(OperatorsStore.isOffline.value ? BODY_CLASS_OFFLINE : BODY_CLASS_ONLINE);
    if (WidgetStore.isOpened.value) {
        result.push(BODY_CLASS_OPENED);
    }
    return result;
});

watch(bodyClass, () => applyBodyClass());

let possibleBodyClasses = computed(() => {
    return [
        initializedClass.value,
        BODY_CLASS_MOBILE,
        BODY_CLASS_DESKTOP,
        BODY_CLASS_ONLINE,
        BODY_CLASS_OFFLINE,
        BODY_CLASS_OPENED,
        BODY_CLASS_NOT_LOADED
    ];
});

function applyBodyClass() {
    let body = document.body;
    if (body) {
        let bodyClassList = body.classList;
        let newClass = bodyClass.value;
        let toRemove = possibleBodyClasses.value.filter(name => newClass.indexOf(name) == -1);
        if (toRemove.length) {
            bodyClassList.remove(...toRemove);
        }
        if (newClass.length) {
            bodyClassList.add(...newClass);
        }
    }
}

let css = computed(() => {
    let apiMethod = ConfigStore.apiMethod.value;
    let selector = `.${BODY_CLASS_NOT_LOADED} .${apiMethod}-online, .${BODY_CLASS_NOT_LOADED} .${apiMethod}-offline, .${BODY_CLASS_ONLINE} .${apiMethod}-offline, .${BODY_CLASS_OFFLINE} .${apiMethod}-online`;
    return {
        [selector]: {
            display: "none"
        }
    };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ChatStyle, {
    "append-root-selector": false,
    id: "online-chat-toggle-elements",
    css: _unref(css)
  }, null, 8, ["css"]))
}
}

})