import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]

import Dictionary from "~/ts/library/Dictionary";
import SvgIcon from "~/chat/vue/misc/SvgIcon.vue";
import {computed} from "vue";
import {TriggerStore} from "~/chat/ts/store/Trigger";
import SvgIconBackgroundWrapper from "~/chat/vue/misc/SvgIconBackgroundWrapper.vue";


const __default__ = {
    name: "RoundTriggerInner"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    iconName: {},
    size: {},
    background: {},
    color: {},
    iconStyle: { default: "light" },
    img: {}
  },
  setup(__props: any) {

let props = __props;

let computedSize = computed(() => {
    return props.size ? props.size : TriggerStore.triggerParams.value.roundTriggerSize;
});

let style = computed(() => {
    let roundTriggerSize = computedSize.value;
    let size = `${roundTriggerSize}px !important`;

    let result: Dictionary<string> = {
        width: size,
        height: size,
        fontSize: size,
        background: props.background ? `${props.background} !important` : undefined,
        color: props.color ? `${props.color} !important` : undefined
    };

    return result;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "trigger-round-item trigger-background",
    style: _normalizeStyle(_unref(style))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.img)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "img",
          src: _ctx.img
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createBlock(SvgIconBackgroundWrapper, {
          key: 1,
          class: "icon-wrapper"
        }, {
          default: _withCtx(() => [
            _createVNode(SvgIcon, {
              "icon-name": _ctx.iconName,
              class: "icon",
              "icon-style": _ctx.iconStyle
            }, null, 8, ["icon-name", "icon-style"])
          ]),
          _: 1
        }))
  ], 4))
}
}

})